import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import './index.css';
import './app/fonts/helveticaneue-light.otf'
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { muiTheme } from "./stylesheet";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./app/providers/AuthProvider";
import { SnackProvider } from "./app/providers/SnackProvider";
import enGB from 'date-fns/locale/en-GB';

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={muiTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                    <BrowserRouter>
                        <SnackProvider>
                            <AuthProvider>
                                <App />
                            </AuthProvider>
                        </SnackProvider>
                    </BrowserRouter>
                </LocalizationProvider>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
