import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
import styles from './BidDialog.module.scss';
import { WatchInfoBuyDetails } from "../../../models/WatchInfoBuyDetails";
import {ReactComponent as CheckCircleIcon} from '../../../assets/checkCircle.svg';
import {ReactComponent as ContractIcon} from '../../../assets/contract.svg';
import {ReactComponent as PercentageIcon} from '../../../assets/percentage.svg';
import {ReactComponent as MailboxIcon} from '../../../assets/mailbox.svg';
import { useCallback, useContext, useEffect, useState } from "react";
import { createBid } from "../../../services/auction-service";
import { loggingService } from "../../../../utils/logging/logging";
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from "../../../../constants";
import { SnackContext } from "../../../providers/SnackProvider";
import { HttpClientHttpResponseError } from "@hmxlabs/dax-client/build/services/generated/dax-api/http-client";
import { Link } from "react-router-dom";

const {log} = loggingService('app:BidDialog');

interface BidDialogProps {
    watchDetails: WatchInfoBuyDetails;
    newBid: number;
    isOpen: boolean;    
    onClose: () => void;
}

export const BidDialog = ({watchDetails, newBid, isOpen, onClose}: BidDialogProps) => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    const showSnackbar = useContext(SnackContext);
    const [conditionsAccepted, setConditionsAccepted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isBidConfirmed, setIsBidConfirmed] = useState(false);

    useEffect(() => {
        setConditionsAccepted(false);
        setIsLoading(false);
        setIsBidConfirmed(false);
    }, [watchDetails]);

    const onConditionsAcceptedChanged = useCallback(() => {
        setConditionsAccepted(prev => !prev);
    }, []);

    const onConfirmBidClick = useCallback(async () => {
        setIsLoading(true);

        try {
            await createBid(watchDetails.id, newBid);
            log('Create bid successful');

            setIsBidConfirmed(true);
            setIsLoading(false);
        } catch (error) {
            const httpError: HttpClientHttpResponseError<any,any> = error as HttpClientHttpResponseError<any,any>;
            log('Create bid error', httpError?.httpResponse?.error);
            const title = httpError?.httpResponse?.error?.['title'] ?? 'An error occurred placing the bid. Please try again.';
            showSnackbar(title, {alertSeverity: 'error'});
            setIsBidConfirmed(false);
            setIsLoading(false);
        }
    }, [newBid, showSnackbar, watchDetails.id]);

    return (
        <Dialog fullWidth={true} open={isOpen} onClose={onClose} className={isDesktop ? styles.root : styles.rootMobile}>
            <DialogTitle className={styles.dialogTitleContainer}>
                {isBidConfirmed ? (
                    <Typography className={styles.bidConfirmedText}>Bid Confirmed!</Typography>
                ) : (
                    <Typography className={styles.titleText}>Confirm your bid</Typography>
                )}
                
                <IconButton aria-label="Close" onClick={onClose} sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                }}>
                    <CloseIcon className={styles.closeIcon} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.contentContainer}>
                <Stack direction='column'>
                    {isBidConfirmed ? (
                        <Typography className={styles.descriptionText}>To view all listings you have active bids on, go to your account overview.</Typography>
                    ) : (
                        <Typography className={styles.descriptionText}>By clicking the ‘Confirm bid’ button, you accept the following conditions:</Typography>
                    )}
                    
                    <Stack direction='column' className={styles.watchImageContainer}>
                        {watchDetails.showroomImage?.thumbnailUrl ? (
                            <img className={styles.watchImage} src={watchDetails.showroomImage.thumbnailUrl} alt="" />
                        ) : (
                            <Stack direction='column' className={styles.watchNoImageContainer}>
                                <NoPhotographyOutlinedIcon />
                                <Typography className={styles.watchNoImageText}>Image Unavailable</Typography>
                            </Stack>
                        )}
                    </Stack>

                    {isBidConfirmed && (
                        <div className={styles.bidConfirmedContainer} />
                    )}

                    {!isBidConfirmed && isLoading && (
                        <Stack direction='row' className={styles.loadingContainer}>
                            <div className={styles.loader} />
                            <Typography className={styles.loadingText}>Loading...</Typography>
                        </Stack>
                    )}
                    
                    {!isBidConfirmed && !isLoading && (
                        <>
                            <Stack direction='row' className={styles.conditionsContainer}>
                                <Stack direction='row' className={styles.iconContainer}>
                                    <CheckCircleIcon className={styles.icon}/>
                                </Stack>
                                <Typography className={styles.descriptionText}>You commit to purchasing if your bid meets or exceeds the reserve price</Typography>
                            </Stack>
                            <Stack direction='row' className={styles.conditionsContainer}>
                                <Stack direction='row' className={styles.iconContainer}>
                                    <ContractIcon className={styles.icon}/>
                                </Stack>
                                <Typography className={styles.descriptionText}>All bids are legally binding and cannot be withdrawn</Typography>
                            </Stack>
                            <Stack direction='row' className={styles.conditionsContainer}>
                                <Stack direction='row' className={styles.iconContainer}>
                                    <PercentageIcon className={styles.icon}/>
                                </Stack>
                                <Typography className={styles.descriptionText}>Should you win the auction, your bid will incur a fee of 5% (plus VAT) payable to WatchShuffle Ltd</Typography>
                            </Stack>
                            <Stack direction='row' className={styles.conditionsContainer}>
                                <Stack direction='row' className={styles.iconContainer}>
                                    <MailboxIcon className={styles.icon}/>
                                </Stack>
                                <Typography className={styles.descriptionText}>You are responsible for all shipping costs, including insurance, or you may choose to collect the watch from our London office</Typography>
                            </Stack>

                            <Stack direction='row' className={styles.acceptConditionsContainer}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox className={styles.rememberMeInput} checked={conditionsAccepted} onChange={onConditionsAcceptedChanged}/>} label={<Typography className={styles.descriptionText}>I accept the <b><u><Link to="/terms&conditions" target="_blank">Terms and Conditions</Link></u></b></Typography>} />
                                </FormGroup>
                            </Stack>
                        </>
                    )}

                    <Stack direction='column' className={styles.dividerContainer}>
                        <div className={styles.divider} />
                    </Stack>

                    <Stack direction='row' className={styles.bidContainer}>
                        <Stack direction='column' className={styles.yourBidContainer}>
                            <Typography className={styles.bidTitle}>Your Bid</Typography>
                            <Typography className={styles.bidValue}>{`£${newBid.toLocaleString()}`}</Typography>
                        </Stack>

                        <div className={styles.spacer} />

                        <Stack direction='row' className={styles.confirmContainer}>
                            {isBidConfirmed ? (
                                // <Button className={styles.confirmButton} variant="contained" disableElevation>
                                //     <Typography className={styles.confirmText}>View your bids</Typography>
                                // </Button>
                                <Button className={styles.confirmButton} variant="contained" disableElevation onClick={onClose}>
                                    <Typography className={styles.confirmText}>Close</Typography>
                                </Button>
                        ) : (
                                <Button className={styles.confirmButton} variant="contained" disableElevation disabled={!conditionsAccepted || isLoading} onClick={onConfirmBidClick}>
                                    <Typography className={styles.confirmText}>Confirm bid</Typography>
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}