import { useCallback, useMemo } from "react";

export const useFetchWrapper = () => {
    const request = useCallback((method: 'GET' | 'POST') => {
        return (url: string, body?: object) => {
            const init: RequestInit = {};
            init.method = method;
            init.headers = {
                Accept: 'application/json'
            };

            if (body) {
                init.headers['Content-Type'] = 'application/json';
                init.body = JSON.stringify(body);
            }

            return fetch(url, init);
        }
    }, []);

    const requestWithToken = useCallback((method: 'GET' | 'POST') => {
        return (url: string, accessToken: string, body?: any, withoutType?: boolean) => {
            const init: RequestInit = {};
            init.method = method;
            init.headers = withoutType ? {
                Authorization: `Bearer ${accessToken}`,
            } : {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json'
            };

            if (body && withoutType) {
                init.body = body;
            }else {
                init.headers['Content-Type'] = 'application/json';
                init.body = JSON.stringify(body);
            }

            return fetch(url, init);
        }
    }, []);
 
    const get = useMemo(() => request('GET'), [request]);
    const post = useMemo(() => request('POST'), [request]);
    const getWithToken = useMemo(() => requestWithToken('GET'), [requestWithToken]);
    const postWithToken = useMemo(() => requestWithToken('POST'), [requestWithToken]);

    return {
        get,
        post,
        getWithToken,
        postWithToken
    };
}