import { Stack, Typography } from "@mui/material";
import NoPhotographyOutlinedIcon from "@mui/icons-material/NoPhotographyOutlined";
import FileUploadComponent from "../../fileupload/FileUploadComponent";
import { FileUploadInfo } from "../../fileupload/FileUploadItem";

import styles from "./EditImages.module.scss";

interface ShowroomContainerProps {
    showroomImage?: string;
    showroomAttach: FileUploadInfo[],
    setShowroomAttach: React.Dispatch<React.SetStateAction<any>>;
}

const ShowroomContainer = ({
    showroomImage,
    showroomAttach,
    setShowroomAttach
}: ShowroomContainerProps) => {
    return (
        <Stack>
            <Stack direction="column" className={styles.watchImageContainer}>
                {
                    showroomImage ? (
                    <img
                        className={styles.showroomImage}
                        src={showroomImage}
                        alt="showroom"
                    />
                ) : 
                (
                    <Stack
                        direction="column"
                        className={styles.watchNoImageContainer}
                    >
                        <NoPhotographyOutlinedIcon />
                        <Typography className={styles.watchNoImageText}>
                            Image Unavailable
                        </Typography>
                    </Stack>
                )}
            </Stack>

            <Stack direction="column" className={styles.dividerContainer}>
                <div className={styles.divider} />
            </Stack>

            <Stack direction="column">
                <FileUploadComponent
                    disabled={showroomAttach.length === 1}
                    setFiles={setShowroomAttach}
                    files={showroomAttach}
                    maxFiles={1}
                />
            </Stack>
        </Stack>
    );
};

export default ShowroomContainer;
