import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import LoadingSpinner from "../../spinner/LoadingSpinner";
import StripeContainer from "./StripeContainer";
import StripeSaveForm from "./StripeSaveForm";

const StripeComplete = () => {
    const [searchParams] = useSearchParams();
    const [setupIntentClientSecret, setSetupIntentClientSecret] = useState('');
    const [stripeError, setStripeError] = useState('');

    useEffect(() => {
        const redirectStatus = searchParams.get('redirect_status');
        const secret = searchParams.get('setup_intent_client_secret');

        if (redirectStatus === 'succeeded' && secret) {
            setSetupIntentClientSecret(secret);
        } else {
            setStripeError('Stripe setup was not completed successfully');
        }
    }, [searchParams]);

    if (stripeError) {
        return (
            <div>
                <h1>Stripe error</h1>
            </div>
        );
    }

    if (!setupIntentClientSecret) {
        return (
            <Stack direction='column'>
                <Stack direction='row'>
                    <LoadingSpinner />
                    <Typography>Finalizing Stripe setup. Please wait...</Typography>
                </Stack>
            </Stack>
        );
    }

    return (
        <StripeContainer elementsOptions={{}}>
            <StripeSaveForm setupIntentClientSecret={setupIntentClientSecret} />
        </StripeContainer>
    );
}

export default StripeComplete;