import React, { useEffect, useState } from "react";
import { Accept, DropEvent, FileRejection, useDropzone } from "react-dropzone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import styles from "./Dropzone.module.scss";
import { IconButton, Typography } from "@mui/material";

interface DropzoneProps {
    onDrop: <T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent,
    ) => void;

    accept: Accept;
    disabled: boolean;
    maxFiles: number;
}

const Dropzone = ({ onDrop, accept, disabled, maxFiles }: DropzoneProps) => {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        acceptedFiles,
        fileRejections,
    } = useDropzone({
        accept,
        onDrop,
        disabled,
        maxFiles: maxFiles ? maxFiles : 0,
    });
    const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            setRejectedFiles([]);
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (fileRejections.length > 0) {
            setRejectedFiles(fileRejections);
        }
    }, [fileRejections]);

    const handleFileUpload = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
    };

    return (
        <div className={styles.root}>
            <div {...getRootProps({ className: "dropzone" })}>
                <input className="input-zone" {...getInputProps()} />

                <div className={styles.dropContainer}>
                    <div className={styles.uploadIconContainer}>
                        <IconButton>
                            <UploadFileIcon className={styles.uploadIcon} />
                        </IconButton>
                    </div>

                    {isDragActive ? (
                        <p className="dropzone-content">
                            Release to drop the files here
                        </p>
                    ) : (
                        <div className={styles.infoContainer}>
                            <Typography className={styles.infoText}>
                                <a href="/" onClick={handleFileUpload}>
                                    Click to upload
                                </a>{" "}
                                or drag and drop
                            </Typography>
                            <Typography className={styles.infoText}>
                                SVG, PNG, JPG or GIF (max. 3MB)
                            </Typography>
                        </div>
                    )}

                    {fileRejections.length > 0 ? (
                        <div className={styles.errorContainer}>
                            <Typography className={styles.errorText}>
                                Invalid image file(s):{" "}
                                {rejectedFiles
                                    .map((x) => x.file.name)
                                    .join(", ")}
                            </Typography>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Dropzone;
