import { Button, Stack, Typography } from "@mui/material";
import { useCallback, useContext, useState } from "react";
import styles from './StripeSummary.module.scss';
import stripeIcon from '../../../assets/stripeIcon.png';
import { AuthContext } from "../../../providers/AuthProvider";
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from "../../../../constants";
import StripeContainer from "./StripeContainer";
import { daxApi } from "@hmxlabs/dax-client";
import { loggingService } from "../../../../utils/logging/logging";
import { StripeElementsOptions } from "@stripe/stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SnackContext } from "../../../providers/SnackProvider";

const {log} = loggingService('app:StripeSummary');

interface GetSetupIntentResponse {
    token: string;
}

const StripeSummary = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });    
    const {user} = useContext(AuthContext);
    const showSnackbar = useContext(SnackContext);
    const [elementsOptions, setElementsOptions] = useState<StripeElementsOptions>({});
    const [showStripe, setShowStripe] = useState(false);
    const [loading, setLoading] = useState(false);

    const setupStripe = useCallback(async () => {
        setLoading(true);

        try {            
            const response = await daxApi.paymentMethodGetSetupIntent<GetSetupIntentResponse>();
            setElementsOptions({
                clientSecret: response.token
            });
            setShowStripe(true);
        } catch (error) {
            log('Error retrieving Stripe setup intent id', error);
            showSnackbar('An error occurred contacting Stripe. Please try again later.', {alertSeverity: 'error'});
            setShowStripe(false);
        } finally {
            setLoading(false);
        }
    }, [showSnackbar]);

    if (showStripe) {
        return (
            <StripeContainer elementsOptions={elementsOptions}>
                <StripePaymentForm />
            </StripeContainer>
        );
    }

    return (
        <Stack direction='column' className={isDesktop ? styles.root : styles.rootMobile}>
            {user?.payment_method_attached ? (
                <Stack direction='row' className={styles.setupCompleteContainer}>
                    <CheckCircleOutlineIcon className={styles.setupIcon} />
                    <Typography className={styles.setupText}>Stripe Setup Complete</Typography>
                </Stack>
            ) : (
                <Stack direction='row' className={styles.setupRequiredContainer}>
                    <ErrorOutlineIcon className={styles.setupIcon} />
                    <Typography className={styles.setupText}>Stripe Setup Required</Typography>
                </Stack>
            )}
            <Typography className={styles.securityTitle}>For extra security...</Typography>
            <Typography className={styles.securityContent}>We use Stripe to hold and manage your card details along with any ID checks we may require.</Typography>
            {user?.payment_method_attached ? (
                <>
                    <Typography className={styles.securityContent}>To update your card details, please click on the button below and a Stripe pop-up will appear on your screen.</Typography>
                    <Stack direction='row' className={styles.updateCardButtonContainer}>
                        <Button className={styles.updateCardButton} variant="contained" disableElevation onClick={setupStripe} disabled={loading}>
                            <Stack direction='row' className={styles.updateCardContentsContainer}>
                                <img className={styles.updateCardIcon} src={stripeIcon} alt="Update Card Details" />
                                <Typography className={styles.updateCardText}>Update Card Details</Typography>
                            </Stack>
                        </Button>
                    </Stack>
                </>
            ) : (
                    <>
                        <Typography className={styles.securityContent}>To setup your card details, please click on the button below and a Stripe pop-up will appear on your screen.</Typography>
                        <Stack direction='row' className={styles.updateCardButtonContainer}>
                            <Button className={styles.updateCardButton} variant="contained" disableElevation onClick={setupStripe} disabled={loading}>
                                <Stack direction='row' className={styles.updateCardContentsContainer}>
                                    <img className={styles.updateCardIcon} src={stripeIcon} alt="Setup Card Details" />
                                    <Typography className={styles.updateCardText}>Setup Card Details</Typography>
                                </Stack>
                            </Button>
                        </Stack>
                    </>
            )}
        </Stack>
    );
}

export default StripeSummary;