import { Children, useCallback } from "react";
import { Stack } from "@mui/material";
import FileUploadComponent from "../../fileupload/FileUploadComponent";
import ImageCard from "./ImageCard";
import { FileUploadInfo } from "../../fileupload/FileUploadItem";
import { deleteAttachment } from "../../../services/auction-service";

import styles from "./EditImages.module.scss";

interface ConditionsContainerProps {
    itemId: string;
    conditionImages: any[];
    conditionsAttach: FileUploadInfo[];
    setConditionImages: React.Dispatch<React.SetStateAction<any>>;
    setConditionsAttach: React.Dispatch<React.SetStateAction<any>>;
    setRearrange: React.Dispatch<React.SetStateAction<any>>;
    setIsLoading: React.Dispatch<React.SetStateAction<any>>;
    onDelete: (type: string, images: any[]) => void;
}

const ConditionsContainer = ({
    itemId,
    conditionImages,
    setConditionImages,
    conditionsAttach,
    setConditionsAttach,
    onDelete,
    setRearrange,
    setIsLoading,
}: ConditionsContainerProps) => {
    const onDeleteConditionImage = useCallback(
        async (image: any) => {
            setIsLoading(true);
            await deleteAttachment(itemId, image);
            setConditionImages((prev: any) =>
                prev.filter((prevItem: any) => prevItem.id !== image),
            );
            onDelete(
                "condition",
                conditionImages.filter(
                    (prevItem: any) => prevItem.id !== image,
                ),
            );
        },
        [setConditionImages, conditionImages, onDelete, itemId, setIsLoading],
    );

    const moveConditionImage = useCallback(
        (dragIndex: any, hoverIndex: any) => {
            setConditionImages((prevCards: any) => {
                let clonedCards = [...prevCards];
                const removedItem = clonedCards.splice(dragIndex, 1)[0];
                const hoverItem = clonedCards[hoverIndex];
                clonedCards = clonedCards.map((item, index) => {
                    if (index === hoverIndex)
                        return { ...item, sortOrder: removedItem.sortOrder };
                    return item;
                });
                clonedCards.splice(hoverIndex, 0, {
                    ...removedItem,
                    sortOrder: hoverItem.sortOrder,
                });
                return clonedCards;
            });
            setRearrange((prev: any) => {
                return { ...prev, conditions: true };
            });
        },
        [setConditionImages, setRearrange],
    );

    return (
        <Stack>
            <Stack direction="column" className={styles.watchImageContainer}>
                <div className={styles.imagesContainer}>
                    {Children.toArray(
                        conditionImages.map((image, index) => (
                            <ImageCard
                                src={image.mediaUrl}
                                id={image.id}
                                key={index}
                                index={index}
                                onDeleteImage={onDeleteConditionImage}
                                moveImage={moveConditionImage}
                            />
                        )),
                    )}
                </div>
            </Stack>

            <Stack direction="column" className={styles.dividerContainer}>
                <div className={styles.divider} />
            </Stack>

            <Stack direction="column">
                <FileUploadComponent
                    disabled={false}
                    setFiles={setConditionsAttach}
                    files={conditionsAttach}
                />
            </Stack>
        </Stack>
    );
};

export default ConditionsContainer;
