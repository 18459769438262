import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styles from './BiddingHistory.module.scss';
import { SnackContext } from "../../../providers/SnackProvider";
import { loggingService } from "../../../../utils/logging/logging";
import { getWatchesForBuyer } from "../../../services/auction-service";
import { WatchInfoBuyDetails } from "../../../models/WatchInfoBuyDetails";
import WatchSummary from "./WatchSummary";
import { AuthContext } from "../../../providers/AuthProvider";
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from "../../../../constants";
import WatchSummaryMobile from "./WatchSummaryMobile";

const {log} = loggingService('app:BiddingHistory');
type StatusKey = 'lotsWon'|'liveBids'|'unsuccessfulBids';
const defaultStatusToItemsMap: Record<StatusKey, WatchInfoBuyDetails[]> = {
    lotsWon: [],
    liveBids: [],
    unsuccessfulBids: []
};

const BiddingHistory = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
    const showSnackbar = useContext(SnackContext);
    const {user} = useContext(AuthContext);
    const [selectedOption, setSelectedOption] = useState<StatusKey>('lotsWon');
    const [statusToItemsMap, setStatusToItemsMap] = useState<Record<StatusKey, WatchInfoBuyDetails[]>>(defaultStatusToItemsMap);

    const onFilterChanged = useCallback((event: SelectChangeEvent) => {
        setSelectedOption(event.target.value as StatusKey);
    }, []);

    const statusClasses = useMemo(() => {
        return {
            standard: styles.statusDropdown,
            select: styles.statusDropdownSelected
        };
    }, []);

    const loadWatches = useCallback(async () => {
        try {
            log('getting watches for buyer');
            const items = await getWatchesForBuyer();
            log('getting watches for buyer result', items);
            
            const initial: Record<StatusKey, WatchInfoBuyDetails[]> = {
                lotsWon: [],
                liveBids: [],
                unsuccessfulBids: []
            };

            const statusMapping = items.reduce((agg, item) => {
                switch (item.status) {

                    case 'Live': {
                        agg.liveBids.push(item);
                        break;
                    }
                    case 'EndedSold': {
                        if (item.winningBid?.user && item.winningBid.user === user?.user_id) {
                            agg.lotsWon.push(item);
                        } else {
                            agg.unsuccessfulBids.push(item);
                        }
                        break;
                    }
                    case 'Rejected':
                    case 'Withdrawn':
                    case 'EndedNotSold': {
                        agg.unsuccessfulBids.push(item);
                        break;
                    }
                }
                
                return agg;
            }, initial);
            
            // Sort the items in each category by the created time 
            Object.values(statusMapping).forEach(items => {
                items.sort((x,y) => x.createdOn === y.createdOn ? 0 : (x.createdOn > y.createdOn ? 1 : -1));
            });

            setStatusToItemsMap(statusMapping);
        } catch (error) {
            log('Error retrieving watches for buyer', error);
            showSnackbar('An error occurred loading the watch listings. Please try again later.', {alertSeverity: 'error'});
        }
    }, [showSnackbar, user?.user_id]);

    const handleMenuOptionClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>, option: StatusKey) => {
        e.preventDefault();
        setSelectedOption(option);
    }, []);

    const refreshListings = useCallback(() => {
        loadWatches();
    }, [loadWatches]);

    useEffect(() => {
        loadWatches();
    }, [loadWatches]);

    return isDesktop ? (
        <div className={styles.root}>
            <Stack direction='column' style={{gridColumn: 1}} className={styles.leftContainer}>
                <Stack direction='row' className={selectedOption === 'lotsWon' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'lotsWon')}>Lots Won</a>
                    </Typography>
                    <Stack direction='row' className={styles.lotsWonContainer}>
                        <Typography className={styles.countLabel}>{statusToItemsMap?.lotsWon?.length ?? 0}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' className={selectedOption === 'liveBids' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'liveBids')}>Live Bids</a>
                    </Typography>
                    <Stack direction='row' className={styles.liveBidsContainer}>
                        <Typography className={styles.countLabel}>{statusToItemsMap?.liveBids?.length ?? 0}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' className={selectedOption === 'unsuccessfulBids' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'unsuccessfulBids')}>Unsuccessful Bids</a>
                    </Typography>
                    <Stack direction='row' className={styles.unsuccessfulBidsContainer}>
                        <Typography className={styles.countLabel}>{statusToItemsMap?.unsuccessfulBids?.length ?? 0}</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack direction='column' style={{gridColumn: 2}}>
                {statusToItemsMap?.[selectedOption]?.length ?
                    statusToItemsMap?.[selectedOption]?.map(watchDetails =>(
                        <WatchSummary key={watchDetails.id} watchDetails={watchDetails} refreshListings={refreshListings} />
                    )) : (
                        <Stack className={styles.noItemsContainer} direction='column'>
                            <Typography className={styles.noItemsLabel}>No watches found.</Typography>
                        </Stack>
                    )
                }
            </Stack>
        </div>
    ) : (
        <div className={styles.rootMobile}>
            <div className={styles.statusFilterContainerMobile}>
                <Select classes={statusClasses} variant="standard" value={selectedOption} onChange={onFilterChanged}>
                    <MenuItem value={'lotsWon'}>
                        <Stack direction='row'>
                            <Typography className={styles.statusText}>Lots Won</Typography>
                            <Stack direction='row' className={styles.lotsWonContainerMobile}>
                                <Typography className={styles.countLabelMobile}>{statusToItemsMap?.lotsWon?.length ?? 0}</Typography>
                            </Stack>
                        </Stack>
                    </MenuItem>
                    <MenuItem value={'liveBids'}>
                        <Stack direction='row'>
                            <Typography className={styles.statusText}>Live Bids</Typography>
                            <Stack direction='row' className={styles.liveBidsContainerMobile}>
                                <Typography className={styles.countLabelMobile}>{statusToItemsMap?.liveBids?.length ?? 0}</Typography>
                            </Stack>
                        </Stack>
                    </MenuItem>
                    <MenuItem value={'unsuccessfulBids'}>
                        <Stack direction='row'>
                            <Typography className={styles.statusText}>Unsuccessful Bids</Typography>
                            <Stack direction='row' className={styles.unsuccessfulBidsContainerMobile}>
                                <Typography className={styles.countLabelMobile}>{statusToItemsMap?.unsuccessfulBids?.length ?? 0}</Typography>
                            </Stack>
                        </Stack>
                    </MenuItem>
                </Select>   
            </div>            
            
            <Stack direction='column'>
                {statusToItemsMap?.[selectedOption]?.length ?
                    statusToItemsMap?.[selectedOption]?.map(watchDetails =>(
                        <WatchSummaryMobile key={watchDetails.id} watchDetails={watchDetails} refreshListings={refreshListings} />
                    )) : (
                        <Stack className={styles.noItemsContainer} direction='column'>
                            <Typography className={styles.noItemsLabel}>No watches found.</Typography>
                        </Stack>
                    )
                }
            </Stack>            
        </div>
    );
}

export default BiddingHistory;