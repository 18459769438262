import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import {
    IconButton,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export interface ImageCardProps {
    src: string;
    index: number;
    id: string;
    onDeleteImage: (image: string) => void;
    moveImage: (dragIndex: any, hoverIndex: any) => void;
}
const ImageCard = ({ src, onDeleteImage, moveImage, index, id }: ImageCardProps) => {
    const ref = useRef<any>(null);

    const [, drop] = useDrop({
        accept: "image",
        hover: (item: any, monitor) => {
          if (!ref.current) {
            return;
          }
          const dragIndex = item.index;
          const hoverIndex = index;
    
          if (dragIndex === hoverIndex) {
            return;
          }
    
          const hoverBoundingRect = ref.current?.getBoundingClientRect();
    
          const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    
          const clientOffset: any = monitor.getClientOffset();
          const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
          }
    
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
          }
    
          moveImage(dragIndex, hoverIndex);
    
          item.index = hoverIndex;
        },
      });

    const [{ isDragging }, drag] = useDrag({
        type: "image",
        item: () => {
          return { index };
        },
        collect: (monitor) => {
          return {
            isDragging: monitor.isDragging(),
          };
        },
      });
    
      const opacity = isDragging ? 0 : 1;
      drag(drop(ref));

    return (
        <Card variant="outlined" ref={ref} style={{ opacity }}>
            <CardContent style={{ padding: "0px" }}>
                <img
                    style={{ width: "100%" }}
                    src={src}
                    alt="showroom"
                />
            </CardContent>
            <CardActions
                style={{
                    justifyContent: "flex-end",
                    padding: 0
                }}
            >
                <IconButton
                    onClick={() => onDeleteImage(id)}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                >
                    
                   <DeleteIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

export default ImageCard;
