import { TermsAndConditions } from "./TermsItem";

export const termsConditons: TermsAndConditions[] = [{
    id: "b1",
    question: "Identification of the Company",
    answer: [
        `<b>Identity of Us:</b> "Watch Shuffle," also referred to as "we," "us," and "our," identifies Watch Shuffle Ltd. Our company is registered under number 14074692, with our principal office located at J O Hunter House, 409 Bradford Road, Huddersfield, West Yorkshire, England, HD2 2RB.`,
    ]
},
{
    id: "b2",
    question: "Application of Terms",
    answer: [
        `<b>Understanding Our Terms:</b> These Terms govern your interaction with and use of our website <a href='https://watchshuffle.com/'>www.watchshuffle.com</a>, along with any associated services, applications, or tools (referred to collectively as the “Site”). Your use of the Site is also subject to:`,
        'The Privacy Policy, which explains how we process your personal data ([insert hyperlink])',
        'The Cookie Policy, which outlines our use of cookies ([insert hyperlink]).'
    ]
},
{
    id: "b3",
    question: "Reading the Terms",
    answer: ["It is important that you read these Terms thoroughly before accessing the Site."]
},
{
    id: "b4",
    question: "Agreement to Terms",
    answer: [
        'By accessing the Site or indicating your agreement in any form, you consent to be bound by these Terms and the documents they reference.'
    ]
},
{
    id: "b5",
    question: "Disagreement with Terms",
    answer: ['If you disagree with any of these Terms, you should discontinue your use of the Site immediately.']
},
{
    id: "b6",
    question: "Contact and Complaints",
    answer: ['For enquiries or complaints about our services, please email us at <a href="mailto:hello@watchshuffle.com">hello@watchshuffle.com</a>.']
},
{
    id: "b7",
    question: "Complaint Resolution:",
    answer: [
        'We will acknowledge any service-related complaint within two working days.',
        'Depending on the issue, actions we may take include correcting the service, issuing a refund proportionate to fees already paid, or charging an appropriate fee if no payment has been made.',
        'Unresolved issues can be escalated to our Head of Dispute Resolution at the same email, or for residents in the EU, Norway, Iceland, or Liechtenstein, the Online Dispute Resolution platform can be contacted.'
    ]
},
{
    id: "b8",
    question: "Language of Communication",
    answer: ['All communications, documentation, and notices concerning this contract will be conducted in English. Should any translation be provided, the English version will take precedence.']
},
{
    id: "b9",
    question: "Definitions and Key Terms",
    answer: [
        'Auction Lot: Refers to any item listed on the Site for auction, including watches, boxes, documentation, service records, straps, cases, and other accessories.',
        'Buyer: Any individual or entity registered and approved to place bids on Auction Lots.',
        'Content: All forms of multimedia content, text, images, videos, and other materials uploaded to or present on the Site.',
        'Papers: Original manufacturer guarantees issued at the point of sale by an authorised dealer.',
        'End to End Managed Service: Encompasses transportation, inspection, insured storage, and photography of Auction Lots, alongside managing enquiries from potential buyers.',
        'Seller: An individual or entity approved to list Auction Lots for sale on the Site.',
        'Terms: These conditions as may be updated from time to time.',
        'Trade Seller: A Seller who engages in business or commercial activities rather than for personal use.',
        'Working Days: Monday to Friday, excluding public holidays in London, UK.',
        'You/Your: Refers to both Buyers and Sellers, along with any other users of the Site.'
    ]
},
{
    id: "b10",
    question: "Usage of the Site",
    answer: [
        '<b>Function of the Platform:</b> We provide a platform where Sellers can list and Buyers can bid on Auction Lots. We do not act as agents for the Sellers nor are we a party to the contract between Buyer and Seller.'
    ]
},
{
    id: "b11",
    question: "User Responsibilities:",
    answer: ['You bear all costs and expenses arising from your use of the Site.']
},
{
    id: "b12",
    question: "Consumer Protection:",
    answer: ['Your statutory rights as a consumer are not affected by your use of the Site.']
},
{
    id: "b13",
    question: "Access Suspension:",
    answer: ['We reserve the right to suspend your access or remove your listings for non-compliance with these Terms or applicable laws.']
},
{
    id: "b14",
    question: "Site Usage Agreement:",
    answer: [
        'You agree not to use the Site for illegal activities, to commit fraud, to distribute viruses, or to engage in phishing. You also agree not to interfere with our operations or infringe on any laws regarding sanctions.'
    ]
},
{
    id: "b15",
    question: "Your Account with Us",
    answer: [
        '<b>Account Eligibility:</b> The Site is intended for users who are at least 18 years of age. Registration requires providing personal details and agreeing to these Terms.',
        '<b>Payment Setup:</b> Buyers must provide a valid method for fees, while Sellers need to supply payment details for fee deductions.',
        '<b>Identity and Credit Checks:</b> Both Watch Shuffle and Stripe reserve the right to perform necessary identity and credit checks.',
        '<b>Security of Your Account:</b> You are responsible for maintaining the confidentiality of your account credentials.',
        '<b>Account Security Breach:</b> If you believe your account security has been breached, you must inform us promptly.',
    ]
},
{
    id: "b16",
    question: "Listing and Selling on Our Site",
    answer: [
        '<b>Listing Procedure:</b> To list an Auction Lot, you must first submit a request along with photographs and relevant details about the item.',
        '<b>Approval of Listings:</b> We reserve the right to assess and approve Auction Lots before they can be listed on the Site.',
        '<b>Exclusive Listing Period:</b> Once approved, the Auction Lot must be listed exclusively with us for the duration specified for the auction.',
        '<b>Reserve Prices: </b> You may set a reserve price for an Auction Lot, subject to our consent. This reserve may be adjusted downwards but not increased without prior written approval.',
        '<b>Auction Commitment: </b> After approval, you are committed to the auction process, including the exclusivity of the listing during the auction period.',
        '<b>Price Representation:</b> We provide no guarantees on the potential selling price of Auction Lots; any provided estimates are speculative and subject to change.'
    ]
},
{
    id: "b17",
    question: "Delivery and Insurance",
    answer: [
        '<b>Insurance and Delivery Options:</b> Sellers can either hand-deliver their Auction Lots to our office or opt for collection by our designated courier.',
        '<b>Insurance from Receipt:</b> Insurance coverage begins once we receive and acknowledge receipt of the Auction Lot.',
        '<b>Security and Insurance Terms:</b> The security and insurance cover specifics will be provided upon receipt of the Auction Lot.'
    ]
},
{
    id: "b18",
    question: "Transactional Integrity",
    answer: [
        `<b>Seller's Representations:</b> As a Seller, you warrant that you are the legal owner of the Auction Lot and have the right to sell it, free from any encumbrances.`,
        '<b>Sale and Withdrawal Fees:</b> If you withdraw an Auction Lot from sale or fail to complete a sale, you will incur fees calculated based on the reserve price or a minimum specified amount.',
        '<b>Non-Completion Charges:</b> Failure to complete a sale after an Auction Lot has been sold may result in additional charges or fees.'
    ]
},
{
    id: "b19",
    question: "Bidding and Buying",
    answer: [
        `<b>Bidder Responsibilities:</b> Bidders must review Auction Lot descriptions and are responsible for their bids. Errors in bidding are the sole responsibility of the bidder`,
        '<b>Bid Obligations:</b> Each bid placed is a binding commitment to purchase the Auction Lot if it is the highest bid accepted by the Seller.',
    ]
},
{
    id: "b20",
    question: "Payment of Fees:",
    answer: [
        'Successful bidders are obligated to pay any applicable fees, as detailed in the auction terms.'
    ]
},
{
    id: "b21",
    question: "Fees and Payments",
    answer: [
        '<b>Fee Structure:</b> Specific fees associated with listing, selling, or buying will be detailed and may include listing fees, transaction fees, or other service charges.',
        '<b>Payment Processing:</b> We will process payments through Stripe, ensuring secure handling of your payment details.'
    ]
},
{
    id: "b22",
    question: "Disputes and Liability",
    answer: [
        '<b>Resolution of Disputes:</b> Disputes between Buyers and Sellers should be resolved independently without our intervention.',
        '<b>Limitation of Liability:</b> Our responsibility for issues arising from a transaction is limited to facilitating the platform where the transaction takes place.'
    ]
},
{
    id: "b23",
    question: "Account Cancellation and Termination",
    answer: [
        '<b>Cancellation Rights:</b> You have the right to cancel your account at any time, subject to any ongoing auction commitments.',
        '<b>Termination by Us:</b> We reserve the right to terminate your account or access to the Site if you breach these Terms or engage in prohibited activities.'
    ]
},
{
    id: "b24",
    question: "Seller's Content and Responsibilities",
    answer: [
        '<b>Seller Liability for Content:</b> Watch Shuffle will not be held liable for any inaccuracies or omissions in the descriptions provided by the Seller. The Seller is solely responsible for the accuracy and completeness of the information provided.',
        '<b>Accuracy and Risk:</b> You, as the Seller, bear all risks associated with the accuracy and completeness of the Auction Lot descriptions you provide. Any reliance placed by third parties on this information is at their own risk.',
        '<b>Notification of Errors:</b> If you, as the Seller, identify any errors, misstatements, or omissions in the Auction Lot description, or become aware of material alterations, you must notify Watch Shuffle immediately. Adjustments will be made at our discretion.',
    ]
},
{
    id: "b25",
    question: "Bidding and Purchase",
    answer: [
        '<b>Bidding Responsibilities:</b> Bidders are responsible for verifying all information in the Auction Lot descriptions and are expected to conduct their own due diligence.',
        '<b>Bid as Binding Agreement:</b> By placing a bid, you commit to purchasing the Auction Lot at your bid price if it is the highest accepted bid, subject to any reserve requirements.',
        '<b>Bid Withdrawal:</b> Once placed, bids are binding and cannot be withdrawn unless explicitly allowed under specific circumstances detailed in these terms.',
    ]
},
{
    id: "b26",
    question: "Seller and Buyer Protection",
    answer: [
        '<b>Non-Guarantee of Lot Descriptions:</b> Watch Shuffle provides descriptions for informational purposes only, without guarantee of accuracy. Neither buyers nor sellers should rely solely on this information for making purchasing decisions.',
        '<b>Intellectual Property of Photographs:</b> Photographs taken by or for Watch Shuffle are the property of Watch Shuffle and may not be used by third parties without written permission.',
    ]
},
{
    id: "b27",
    question: "Photography and Representation:",
    answer: [
        'Photographs are for identification purposes only and may not reflect the exact condition of the Auction Lot.'
    ]
},
{
    id: "b28",
    question: "Auction Integrity and Process",
    answer: [
        '<b>Amendment of Lot Descriptions and Estimates:</b> Watch Shuffle reserves the right to amend descriptions and estimates of Auction Lots at any time before or during an auction.'
    ]
},
{
    id: "b29",
    question: "Responsibilities of the Buyer:",
    answer: [
        'Buyers must review all available information and inspect Auction Lots personally or virtually as needed to confirm their conditions and authenticity.'
    ]
},
{
    id: "b30",
    question: "Financial Obligations and Settlements",
    answer: [
        '<b>Payment Obligations:</b> If you are the highest bidder, you are obligated to complete the transaction by paying the agreed-upon amount to the Seller.'
    ]
},
{
    id: "b31",
    question: "Failure to Complete Purchase:",
    answer: [
        'Should you fail to complete a purchase as the highest bidder, you will be responsible for a minimum charge of £500+VAT due to Watch Shuffle Ltd.'
    ]
},
{
    id: "b32",
    question: "Provisional Sale and Acceptance Period:",
    answer: [
        'The highest bid received during a Live Auction will be provisionally accepted, subject to confirmation from the Seller within a specified timeframe.'
    ]
},
{
    id: "b33",
    question: "Post-Auction Procedures",
    answer: [
        '<b>Finalising Sales:</b> Upon successful bidding, arrangements will be made for the transfer of payment and delivery of the Auction Lot.',
        '<b>Seller and Buyer Agreements:</b> It is the responsibility of both parties to finalize the sale, including the arrangement of payment methods and the fulfilment of all agreed-upon terms.'
    ]
},
{
    id: "b34",
    question: "Trade Seller Regulations",
    answer: [
        '<b>Specific Terms for Trade Sellers:</b> Additional terms apply specifically to Trade Sellers, which include advance notice for termination of services and specific data usage terms.',
        '<b>Right of Withdrawal:</b> Trade Sellers must acknowledge that buyers within the UK and the EEA may have a statutory right to withdraw from the purchase within 14 days.'
    ]
},
{
    id: "b35",
    question: "Data Handling and Privacy",
    answer: [
        '<b>Handling of Seller Data:</b> Watch Shuffle may use data derived from your use of the services for business support purposes only as agreed upon.',
        '<b>Buyer Data Privacy:</b> Personal information provided by Buyers will be handled in accordance with the privacy policy of Watch Shuffle.',
    ]
},
{
    id: "b36",
    question: "Auction Process and External Sales",
    answer: []
},
{
    id: "b37",
    question: "Auction Guidelines:",
    answer: [
        'The rules and procedures for conducting sales of Auction Lots are outlined on the Site (insert hyperlink), and additional information can be found (insert hyperlink).'
    ]
},
{
    id: "b38",
    question: "Withdrawal and Extension Rights:",
    answer: [
        'Watch Shuffle reserves the absolute discretion to withdraw any Auction Lot from a Coming Soon list or a Live Auction, extend any auction period, and continue offering any Auction Lot for sale without providing reasons. If an auction is extended, and you have paid any fees as the highest bidder, these fees will be refunded.'
    ]
},
{
    id: "b39",
    question: "External Transactions",
    answer: [
        '<b>Prohibition of External Sales:</b> Both Buyers and Sellers are prohibited from engaging in transactions outside the platform for Auction Lots listed on the Site. Any such sales must still comply with financial obligations to Watch Shuffle based on the last agreed reserve or a minimum fee.'
    ]
},
{
    id: "b40",
    question: "Dispute Resolution and Buyer Protection",
    answer: [
        '<b>Handling Disputes:</b> Any disputes concerning the sale of an Auction Lot should be resolved directly between the Buyer and the Seller without the involvement of Watch Shuffle.'
    ]
},{
    id: "b41",
    question: "Seller and Buyer Fees:",
    answer: [
        'Usage of the Site by Sellers is generally free of charge, except where specified under these Terms. Sellers are liable for fees that would have been payable by the Buyer under certain conditions.'
    ]
},{
    id: "b42",
    question: "Payment and Financial Operations",
    answer: [
        '<b>Fee Pre-authorisation:</b> Upon bidding, Stripe will place a hold on your credit or debit card for the anticipated fee, ensuring you meet financial obligations upon winning.',
        '<b>Commission and Fees:</b> Following a successful bid that meets any reserve requirements set by the Seller, a commission of 5% (exclusive of VAT) on the selling price or a minimum fee, whichever is greater, will be payable to Watch Shuffle.'
    ]
},{
    id: "b43",
    question: "Secure Payment Handling",
    answer: [
        '<b>Payment Processing by Stripe:</b> Stripe, acting on our behalf, will securely process all payment details and handle pre-authorisations and actual transactions.',
        '<b>Payment Finalisation:</b> If your bid is successful, immediate payment of the applicable fees to Watch Shuffle will be processed.',
    ]
},{
    id: "b44",
    question: "Storage Fees and Penalties",
    answer: [
        '<b>Storage Fees:</b> If a Buyer fails to collect a purchased Auction Lot, a daily storage fee will be charged, processed through Stripe without need for further consent.'
    ]
},{
    id: "b45",
    question: "Compliance and Security Measures",
    answer: [
        `<b>Stripe’s Security Protocols:</b> Your payment information is securely handled by Stripe, with encryption and tokenisation to protect your data`,
        '<B>Non-payment Consequences:</b> Failure to complete payment within the specified timeframe will result in cancellation of the purchase, and we will facilitate an alternative sale.'
    ]
},{
    id: "b46",
    question: "Tax and Charges Responsibility",
    answer: [
        '<b>Fee and Tax Liability:</b> All fees charged by Watch Shuffle are exclusive of VAT and other applicable taxes, which are the responsibility of the Buyer or Seller as applicable.',
    ]
},{
    id: "b47",
    question: "Interest on Unpaid Amounts",
    answer: [
        '<b>Interest on Overdue Payments:</b> If payments are not made timely, interest may be charged on the outstanding amount at a rate above the Bank of England’s base rate.'
    ]
},{
    id: "b48",
    question: "Data and Privacy Management",
    answer: [
        '<b>Privacy and Personal Information:</b> Our privacy policy outlines how we handle and protect your personal data and is accessible on our website.',
        '<b>Handling of Payment Data:</b> Stripe handles all payment data under its own privacy terms and conditions, which you acknowledge and accept by using their services.',
    ]
},{
    id: "b49",
    question: "Contractual Cancellation Rights",
    answer: [
        '<b>Cancellation of Services:</b> You may cancel your participation in services provided by Watch Shuffle according to the rights set out here, which do not apply to auction transactions completed via winning bids.',
        '<b>Cancellation Procedures:</b> To cancel, a clear statement such as an email or letter must be sent using the contact details provided in the Terms.',
    ]
},{
    id: "b50",
    question: "Content Submission and Security",
    answer: [
        `<b>Content Responsibility:</b> You are responsible for the legality and propriety of any content you submit to the Site. Watch Shuffle is not liable for the content’s accuracy or legality.`
    ]
},{
    id: "b51",
    question: "Secure Use of the Site:",
    answer: [
        'We strive to ensure the Site’s security but cannot guarantee that all content submitted will remain secure and confidential.'
    ]
},{
    id: "b52",
    question: "Intellectual Property and Usage Rights",
    answer: [
        '<b>User-Generated Content Licences:</b> By submitting content, you grant Watch Shuffle a non-exclusive, transferable license to use your content in connection with the services provided by the Site.',
        '<b>Prohibition Against Unauthorised Use:</b> Content submitted to the Site must not infringe on any third-party rights or privacy expectations.',
    ]
},{
    id: "b53",
    question: "Site Management and Operational Integrity",
    answer: [
        '<b>Site Maintenance and Content Control:</b> Watch Shuffle reserves the right to remove any content that fails to meet our standards or poses a risk to our operations.'
    ]
},{
    id: "b54",
    question: "Intellectual Property Control",
    answer: [
        '<b>Ownership and Control of Content:</b> Content submitted by users remains their property, but Watch Shuffle retains the right to use and distribute the content in relation to our services.'
    ]
},{
    id: "b55",
    question: "Content Management and Legal Compliance",
    answer: [
        '<b>Disclosure of Identity:</b> We reserve the right to disclose your identity to any third party who claims that content posted by you to our site is a violation of their intellectual property rights or their right to privacy.'
    ]
},{
    id: "b56",
    question: "Content Oversight:",
    answer: [
        'We do not take responsibility, nor are we liable to any third party, for the content posted by you on our Site or for any reliance on such content.'
    ]
},{
    id: "b57",
    question: "Intellectual Property and Site Usage",
    answer: [
        '<b>User Contributions:</b> Any posting you make on our site may be reviewed and removed if it does not comply with our content standards.'
    ]
},{
    id: "b58",
    question: "Community Standards and Values:",
    answer: [
        'The views expressed by users on our site do not necessarily represent our views or values.'
    ]
},{
    id: "b59",
    question: "Ownership and Licensing",
    answer: [
        '<b>Rights in Site Content:</b> Upon posting or uploading content to our Site, you grant us a perpetual, worldwide, non-exclusive, royalty-free license to use, reproduce, modify, distribute, and display this content in relation to the services provided by the Site and for promotional purposes.'
    ]
},{
    id: "b60",
    question: "Intellectual Property Rights of the Site:",
    answer: [
        'The Site and all intellectual property rights in it, including but not limited to content, are owned by us or our licensors. We and our licensors reserve all rights not expressly granted in these Terms.'
    ]
},{
    id: "b61",
    question: "Data Usage and Protection",
    answer: [
        '<b>Prohibited Activities:</b> You agree not to engage in unauthorized collection or use of data from our Site, including through automated means such as scraping, data mining, or using bots and similar technologies.',
        '<b>Third-party Data Use:</b> Any use of data obtained from the Site for commercial purposes must be authorized by us through a written agreement.',
    ]
},{
    id: "b62",
    question: "Trademarks and Branding",
    answer: [
        '<b>Trademark Ownership:</b> “Watch Shuffle” and associated logos are trademarks belonging to us. Other trademarks and trade names appearing on the site may be owned by third parties.'
    ]
},{
    id: "b63",
    question: "Site Operation and Reliability",
    answer: [
        '<b>Site Accuracy and Operation:</b> We aim to maintain the accuracy and operation of our Site but do not guarantee that the Site will always be updated, error-free, or continuously available.',
        '<b>Discretionary Site Management:</b> We reserve the right to modify, suspend, or discontinue any aspect of the Site at any time.',
    ]
},{
    id: "b64",
    question: "Information and Content",
    answer: [
        '<b>General Information Provision:</b> The Site may include various forms of content for informational purposes only, which should not be relied upon for specific advice.',
        '<b>Access and Availability:</b> While we aim to ensure the Site is accessible, we do not guarantee uninterrupted access and will not be liable for any availability issues.',
    ]
},{
    id: "b65",
    question: "Legal and Jurisdictional Compliance",
    answer: [
        '<b>Translations and Versions:</b> We may provide translations of our content; however, the English version will prevail in case of discrepancies.',
        '<b>Hyperlinks and Third-party Sites:</b> Links to third-party sites are provided for convenience, and we accept no responsibility for third-party content or sites.',
    ]
},{
    id: "b66",
    question: "Limitations of Liability",
    answer: [
        '<b>Liability Limitations for Consumers:</b> If you are a consumer, we do not exclude legal responsibility for things like death, personal injury, fraud, or fraudulent misrepresentation caused by our negligence.',
        '<b>Liability Limitations for Non-Consumers:</b> If you are not a consumer, we exclude all implied conditions, warranties, representations, or other terms that may apply to our Site or any content on it.',
    ]
},{
    id: "b67",
    question: "Governing Law and Jurisdiction",
    answer: [
        '<b>Jurisdiction:</b> For consumers, the courts of the United Kingdom will have non-exclusive jurisdiction meaning you can bring a claim in your local courts. For business users, the courts of England and Wales will have exclusive jurisdiction.',
        '<b>Applicable Law:</b> English law will apply to these Terms.',
    ]
},
];