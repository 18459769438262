import { Stack, Typography } from "@mui/material";
import { FormEvent, useCallback, useState } from "react";
import styles from './StripePaymentForm.module.scss';
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { loggingService } from "../../../../utils/logging/logging";

const {log} = loggingService('app:StripePaymentForm');

const StripePaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (!stripe || !elements) {
                // Stripe.js hasn't yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            // TODO: find a better way to do this
            const returnUrl = window.location.href.replace('member', 'stripe');
            const result = await stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: returnUrl,
                },
            });

            if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                log(result.error.message);
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                log('Successfully setup payment', result);
            }
        } finally {
            setLoading(false);
        }
    }, [elements, stripe]);

    return (
        <form onSubmit={handleSubmit}>
            <Stack direction='column'>
                <PaymentElement />
                <Stack direction='row' className={styles.elementsButtonContainer}>
                    <button className={styles.saveButton} disabled={loading}>
                        <Typography className={styles.saveText}>Save Payment Details</Typography>
                    </button>
                </Stack>
            </Stack>
        </form>
    );
}

export default StripePaymentForm;