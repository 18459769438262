import { Elements } from "@stripe/react-stripe-js"
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { PropsWithChildren } from "react";

const StripeKey = process.env.REACT_APP_STRIPE_KEY ?? '';
const loadStripePromise = loadStripe(StripeKey);

export interface StripeContainerProps extends PropsWithChildren {    
    elementsOptions: StripeElementsOptions;
}

const StripeContainer = ({elementsOptions, children}: StripeContainerProps) => {
    return (
        <Elements stripe={loadStripePromise} options={elementsOptions}>
            {children}
        </Elements>
    );
}

export default StripeContainer;