import * as signalR from "@microsoft/signalr";
import { loggingService } from "../../utils/logging/logging";
import { ListingUpdateNotification } from "../models/ListingUpdateNotification";

const {log} = loggingService('service:signalr-service');
const Url = process.env.REACT_APP_HUB_ADDRESS ?? `${window.location.protocol}//${window.location.host}/dax-api/hub`;

class Connector {
    private connection: signalR.HubConnection;
    public events: (onListingUpdated: (notification: ListingUpdateNotification) => void) => void;
    static instance: Connector;
    
    constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(Url)
            .withAutomaticReconnect()
            .build();
        this.connection.start()
            .then(() => log('SignalR Connection initialised successfully'))
            .catch(err => log(`Error initialising SignalR connection to URL ${Url}`, err));
        this.events = (onListingUpdated: (notification: ListingUpdateNotification) => void) => {
            this.connection.on("listingUpdated", (notification) => {
                onListingUpdated(notification);
            });
        };
    }

    public newMessage = (messages: string) => {
        this.connection.send("newMessage", "foo", messages).then(x => log("sent"))
    }

    public static getInstance(): Connector {
        if (!Connector.instance)
            Connector.instance = new Connector();
        return Connector.instance;
    }
}
export default Connector.getInstance;