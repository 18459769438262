import { Children, useCallback } from "react";
import { Stack } from "@mui/material";
import FileUploadComponent from "../../fileupload/FileUploadComponent";
import ImageCard from "./ImageCard";
import { FileUploadInfo } from "../../fileupload/FileUploadItem";
import { deleteAttachment } from "../../../services/auction-service";

import styles from "./EditImages.module.scss";

interface DetailsContainerProps {
    itemId: string;
    detailsImages: any[];
    detailsAttach: FileUploadInfo[];
    setDetailsImages: React.Dispatch<React.SetStateAction<any>>;
    setDetailsAttach: React.Dispatch<React.SetStateAction<any>>;
    setRearrange: React.Dispatch<React.SetStateAction<any>>;
    setIsLoading: React.Dispatch<React.SetStateAction<any>>;
    onDelete: (type: string, images: any[]) => void;
}

const DetailsContainer = ({
    detailsImages,
    setDetailsImages,
    detailsAttach,
    setDetailsAttach,
    itemId,
    onDelete,
    setRearrange,
    setIsLoading,
}: DetailsContainerProps) => {
    const onDeleteDetailsImage = useCallback(
        async (image: any) => {
            setIsLoading(true)
            await deleteAttachment(itemId, image);
            setDetailsImages((prev: any) =>
                prev.filter((prevItem: any) => prevItem.id !== image),
            );
            onDelete(
                "details",
                detailsImages.filter((prevItem: any) => prevItem.id !== image),
            );
        },
        [setDetailsImages, detailsImages, onDelete, itemId, setIsLoading],
    );

    const moveDetailsImage = useCallback(
        (dragIndex: any, hoverIndex: any) => {
            setDetailsImages((prevCards: any) => {
                let clonedCards = [...prevCards];
                const removedItem = clonedCards.splice(dragIndex, 1)[0];
                const hoverItem = prevCards[hoverIndex];
                clonedCards = clonedCards.map((item, index) => {
                    if (index === hoverIndex)
                        return { ...item, sortOrder: removedItem.sortOrder };
                    return item;
                });
                clonedCards.splice(hoverIndex, 0, {
                    ...removedItem,
                    sortOrder: hoverItem.sortOrder,
                });
                return clonedCards;
            });
            setRearrange((prev: any) => {
                return { ...prev, details: true };
            });
        },
        [setDetailsImages, setRearrange],
    );

    return (
        <Stack>
            <Stack direction="column" className={styles.watchImageContainer}>
                <div className={styles.imagesContainer}>
                    {Children.toArray(
                        detailsImages.map((image, index) => (
                            <ImageCard
                                src={image.mediaUrl}
                                id={image.id}
                                key={index}
                                index={index}
                                onDeleteImage={onDeleteDetailsImage}
                                moveImage={moveDetailsImage}
                            />
                        )),
                    )}
                </div>
            </Stack>

            <Stack direction="column" className={styles.dividerContainer}>
                <div className={styles.divider} />
            </Stack>

            <Stack direction="column">
                <FileUploadComponent
                    disabled={false}
                    setFiles={setDetailsAttach}
                    files={detailsAttach}
                />
            </Stack>
        </Stack>
    );
};

export default DetailsContainer;
