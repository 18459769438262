import { styled } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";

const GoldSwitch = styled((props: SwitchProps) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 5,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            margin: 2,
            "& + .MuiSwitch-track": {
                backgroundColor: "#A28957",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
            "& .MuiSwitch-thumb": {
                backgroundColor: "#fff",
                width: 22,
                height: 22,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 15,
        height: 15,
        backgroundColor: "rgba(0, 0, 0, 0.64)",
        "&.Mui-checked": {
            width: 10,
            height: 10,
        },
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        height: "auto",
        backgroundColor: "#ECE9E5",
        border: "2px solid rgba(0, 0, 0, 0.24)",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

export default GoldSwitch;