import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./DropDown.module.scss";

function sleep(duration: number): Promise<void> {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, duration);
    });
}

export interface PropsInfo {
    disabled: boolean;
    id: string;
    label: string;
    placeholder: string;
    value: string;
    data: string[];
    onChange: React.Dispatch<React.SetStateAction<any>>;
}
export default function DropDown(props: PropsInfo) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly string[]>([]);
    const loading = open && options.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3);

            if (active) {
                setOptions([...props.data]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading, props.data]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        setOptions(props.data);
    }, [props.data]);

    return (
        <Autocomplete
            freeSolo
            id={props.id}
            disabled={props.disabled}
            sx={{ border: "none" }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={props.value}
            onChange={(e, v) => props.onChange(v)}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    className={styles.watchBrandInput}
                    fullWidth
                    id={props.id}
                    variant="standard"
                    placeholder={props.placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
