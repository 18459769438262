import { format } from "date-fns"

export const getDisplayDate = (date?: Date|string, dateDescription?: string) => {
    if (date) {
        try {
            if (typeof(date) === 'string') {
                return format(new Date(date), 'dd-MMM-yyyy');
            }
            
            return format(date, 'dd-MMM-yyyy');
        } catch (error) {
            console.log('Invalid date', date);
            return '';
        }
    } else if (dateDescription) {
        return dateDescription;
    }

    return '';
}