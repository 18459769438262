import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styles from './WatchDetailsDialog.module.scss';
import { WatchInfoBuyDetails } from "../../../models/WatchInfoBuyDetails";
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from "../../../../constants";
import AuctionBuyDetails from "../../pages/auction/buyDetails/AuctionBuyDetails";

interface WatchDetailsDialogProps {
    watchDetails?: WatchInfoBuyDetails;
    isPreview?: boolean;
    isOpen: boolean;    
    onClose: () => void;
}

export const WatchDetailsDialog = ({watchDetails, isPreview, isOpen, onClose}: WatchDetailsDialogProps) => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
    
    return (
        <Dialog sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "100%",
                margin: '8px'
              },
            },
          }} open={isOpen} onClose={onClose} className={isDesktop ? styles.root : styles.rootMobile}>
            <DialogTitle className={styles.dialogTitleContainer}>
                <Typography className={styles.titleText}>Preview Watch Details</Typography>
                
                <IconButton aria-label="Close" onClick={onClose} sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                }}>
                    <CloseIcon className={styles.closeIcon} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.contentContainer}>
                <AuctionBuyDetails watch={watchDetails} isPreview={isPreview} />
            </DialogContent>
        </Dialog>
    );
}