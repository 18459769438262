import { Button, Stack, Typography } from "@mui/material";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './WatchSummaryMobile.module.scss';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { InitDetail } from "lightgallery/lg-events";
import { LightGallery as LightGalleryPlugin } from "lightgallery/lightgallery";
import { LightGalleryLicense } from "../../../../constants";
import { WatchInfoBuyDetails } from "../../../models/WatchInfoBuyDetails";
import Parser from 'html-react-parser'
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
import { getDisplayDate } from "../../../../utils/date/dateUtils";
import EndTimer from "../../endTimer/EndTimer";
import {ReactComponent as GavelIcon} from '../../../assets/gavel.svg';
import { AuthContext } from "../../../providers/AuthProvider";
import { ActionItem, getActions } from "./WatchSummary.utils";
// import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

export interface WatchSummaryMobileProps {
    watchDetails: WatchInfoBuyDetails;
    refreshListings: () => void;
}


const WatchSummaryMobile = ({watchDetails, refreshListings}: WatchSummaryMobileProps) => {
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const actions = useMemo(() => getActions(watchDetails, user, navigate), [navigate, user, watchDetails]);
    const [showSpecification, setShowSpecification] = useState(true);
    const [showCondition, setShowCondition] = useState(true);
    const lightGalleryRef = useRef<LightGalleryPlugin>();
    const lightGalleryConditionsRef = useRef<LightGalleryPlugin>();
    const allImages = useMemo(() => [
        ...(watchDetails.showroomImage ? [watchDetails.showroomImage] : []),
        ...watchDetails.images
    ], [watchDetails]);
    
    const onInit = (detail: InitDetail) => {
        lightGalleryRef.current = detail.instance;
    };

    const onConditionsInit = useCallback((detail: InitDetail) => {
        lightGalleryConditionsRef.current = detail.instance;
    }, []);

    const handleGalleryShow = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        if (lightGalleryRef.current) {
            lightGalleryRef.current.openGallery();
        }
    };

    const handleConditionShow = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        if (lightGalleryConditionsRef.current) {
            lightGalleryConditionsRef.current.openGallery();
        }
    }, []);

    const performAction = useCallback((action: ActionItem) => {
        return async () => {
            const shouldRefresh = await action.action();

            if(shouldRefresh) {
                refreshListings();
            }
        }
    }, [refreshListings]);
    
    const getActionButtons = useCallback((actions: ActionItem[]) => {
        return (
            <>
                {actions.map(action => (
                    <Button key={action.id} className={styles.actionButton} variant="contained" disableElevation onClick={performAction(action)}>
                        <Typography className={styles.actionText}>{action.name}</Typography>
                    </Button>
                ))}
            </>
        )
    }, [performAction]);

    return (
        <Stack direction='column' className={styles.root}>
            <Stack direction='column' className={styles.picturesContainer}>
                {allImages.length > 0 ? (
                    <>
                        <div className={styles.mainImageContainer}>
                            <a className={styles.imageOverlayLink} href='/' onClick={handleGalleryShow}>
                                <img alt="img1" src={allImages[0].thumbnailUrl} className={styles.mainImage} />
                                {/* <ZoomOutMapIcon className={styles.overlayIcon} /> */}
                            </a>
                        </div>
                        <div className={styles.otherImagesContainer}>
                            <LightGallery
                                licenseKey={LightGalleryLicense}
                                speed={500}
                                onInit={onInit}
                                plugins={[lgThumbnail, lgZoom]}
                                getCaptionFromTitleOrAlt={false}
                                showCloseIcon={true}
                            >
                                {allImages.map((image, index) => (
                                    <a key={`${watchDetails.id}_${image.mediaUrl}`} href={image.mediaUrl}><img alt="img" src={image.thumbnailUrl} className={index === 0 ? styles.thumbnailImageFirst : styles.thumbnailImage} /></a>
                                ))}
                            </LightGallery>
                            {allImages.length > 3 && (
                                <a href='/' onClick={handleGalleryShow}>
                                    <div className={styles.moreContainer} >
                                        <Typography className={styles.moreText}>+{allImages.length - 3}</Typography>
                                    </div>
                                </a>
                            )}
                        </div>
                    </>
                ) : (
                    <Stack direction='column' className={styles.watchNoImageContainer}>
                        <NoPhotographyOutlinedIcon />
                        <Typography className={styles.watchNoImageText}>Image Unavailable</Typography>
                    </Stack>
                )}
            </Stack>

            <Stack direction='column' className={styles.statusContainer}>
                <Typography className={styles.statusText} noWrap>STATUS</Typography>
                <Typography className={styles.statusValueText} noWrap>{watchDetails.status}</Typography>
            </Stack>

            <Stack direction='column' className={styles.detailsContainer}>
                <div className={styles.titleAndReserveContainer}>
                    <Stack direction='column' style={{gridColumn: 1}}>
                        <Typography className={styles.brandText}>{watchDetails.brand}</Typography>
                        <Typography className={styles.modelText}>{watchDetails.model}</Typography>
                        <Typography className={styles.yearText}>{watchDetails.year}</Typography>
                    </Stack>

                    <Stack direction='column' style={{gridColumn: 2}}>
                        {watchDetails.status === 'Live' ? (
                            <Stack direction='column' className={styles.bidDetailsContainer}>
                                <Stack direction='column'>
                                    <Stack direction='column' className={styles.currentBidContainer}>
                                        <Typography className={styles.currentBidLabel} noWrap>CURRENT BID</Typography>
                                        <Typography className={watchDetails.currentBid ? styles.currentBidValue : styles.currentBidNoValue} noWrap>{watchDetails.currentBid ? `£${watchDetails.currentBid.toLocaleString()}` : 'No bids'}</Typography>
                                    </Stack>
                                    
                                    <Stack direction='row' className={styles.spacerContainer}>
                                        <div className={styles.spacer} />
                                    </Stack>

                                    <Stack direction='row' className={styles.favouritesContainer}>
                                        <Typography className={styles.bidCountValue}>{watchDetails.bids}</Typography>
                                        <GavelIcon className={styles.gavelIcon}/>
                                        <Typography className={styles.gavelBase}>_</Typography>
                                        {/* <Typography className={styles.favouritesCountValue}>{watchDetails.savedAsFavouriteCount}</Typography>
                                        <FavoriteIcon className={styles.favouriteIcon}/> */}
                                    </Stack>
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack direction='column' className={styles.reserveContainer}>
                                <Typography className={styles.reserveText} noWrap>RESERVE</Typography>
                                <Typography className={styles.reserveValueText} noWrap>{watchDetails.reservePrice ? `£${watchDetails.reservePrice.toLocaleString()}` : 'No reserve'}</Typography>
                            </Stack>
                        )}
                    </Stack>
                </div>

                <Stack direction='column' className={styles.summaryContainer}>
                    {watchDetails.summary.split('||').map((line, index) => line === '' ? (
                        <div key={`${watchDetails.id}_summary_${index}`} className={styles.summarySpacer} />
                    ) : (
                        <Typography key={`${watchDetails.id}_summary_${index}`} className={styles.summaryText}>
                            {Parser(line)}
                        </Typography>
                    ))}
                </Stack>

                <div className={styles.listingDetailsContainer}>
                    {['Draft', 'Rejected', 'Withdrawn'].includes(watchDetails.status) && (
                        <Stack direction='column' style={{gridRow: 1, gridColumn: 1}} className={styles.listingDetailsItemContainer}>
                            <Typography className={styles.listingDetailsTitle} noWrap>CREATION DATE</Typography>
                            <Typography className={styles.listingDetailsText}>{getDisplayDate(watchDetails.createdOn)}</Typography>
                        </Stack>
                    )}

                    {['Accepted', 'ComingSoon'].includes(watchDetails.status) && (
                        <Stack direction='column' style={{gridRow: 1, gridColumn: 1}} className={styles.listingDetailsItemContainer}>
                            <Typography className={styles.listingDetailsTitle} noWrap>DATE TO BE LISTED</Typography>
                            <Typography className={styles.listingDetailsText}>{getDisplayDate(watchDetails.auctionStartDate, watchDetails.auctionStartDateDescription)}</Typography>
                        </Stack>
                    )}

                    {['Live', 'EndedSold', 'EndedNotSold'].includes(watchDetails.status) && (
                        <Stack direction='column' style={{gridRow: 1, gridColumn: 1}} className={styles.listingDetailsItemContainer}>
                            <Typography className={styles.listingDetailsTitle} noWrap>DATE LISTED</Typography>
                            <Typography className={styles.listingDetailsText}>{getDisplayDate(watchDetails.auctionStartDate)}</Typography>
                        </Stack>
                    )}
                    
                    <Stack direction='column' style={{gridRow: 1, gridColumn: 3}} className={styles.listingDetailsItemContainer}>
                        <Typography className={styles.listingDetailsTitle} noWrap>PAYMENT TYPES ACCEPTED</Typography>
                        <Typography className={styles.listingDetailsText}>Stripe</Typography>
                    </Stack>

                    {watchDetails.status === 'Live' && (
                        <>
                        <Stack direction='column' style={{gridRow: 2, gridColumn: 1}} className={styles.listingDetailsItemContainer}>
                            <Typography className={styles.listingDetailsTitle} noWrap>RESERVE</Typography>
                            <Typography className={styles.listingDetailsText}>{watchDetails.reservePrice ? `£${watchDetails.reservePrice.toLocaleString()}` : 'No reserve'}</Typography>
                        </Stack>

                        <Stack direction='column' style={{gridRow: 2, gridColumn: 3}} className={styles.listingDetailsItemContainer}>
                            <Typography className={styles.listingDetailsTitle} noWrap>AUCTION ENDS</Typography>
                            <Stack direction='row'>
                                <EndTimer endDate={watchDetails.auctionEndDate} endDateDescription={watchDetails.auctionEndDateDescription} isLarge={false} onCountdownFinished={refreshListings} />
                            </Stack>
                        </Stack>
                        </>
                    )}

                    {['EndedNotSold', 'EndedSold'].includes(watchDetails.status) && (
                        <>
                        <Stack direction='column' style={{gridRow: 2, gridColumn: 1}} className={styles.listingDetailsItemContainer}>
                            <Typography className={styles.listingDetailsTitle} noWrap>RESERVE</Typography>
                            <Typography className={styles.listingDetailsText}>{watchDetails.reservePrice ? `£${watchDetails.reservePrice.toLocaleString()}` : 'No reserve'}</Typography>
                        </Stack>

                        <Stack direction='column' style={{gridRow: 2, gridColumn: 3}} className={styles.listingDetailsItemContainer}>
                            <Typography className={styles.listingDetailsTitle} noWrap>AUCTION ENDED</Typography>
                            <Typography className={styles.listingDetailsText}>{getDisplayDate(watchDetails.auctionEndDate)}</Typography>
                        </Stack>
                        </>
                    )}                    
                </div>

                <Stack direction='row' className={styles.specsHeaderContainer}>
                    <Typography className={styles.specsHeaderText} noWrap>Specification</Typography>
                    <div className={styles.specsButton} onClick={e => setShowSpecification(prev => !prev)}>                        
                        {showSpecification ?
                            <KeyboardArrowDownIcon className={styles.specsButtonImage}/>
                        :
                            <KeyboardArrowUpIcon className={styles.specsButtonImage}/>
                        }
                    </div>
                </Stack>

                <div className={styles.divider}>
                    <div className={styles.dividerItem} />
                </div>

                {showSpecification && (
                    <Stack direction='row' className={styles.specsContainer}>
                        <Stack direction='column'>
                            <Typography className={styles.specTitle} noWrap>Model Number:</Typography>
                            <Typography className={styles.specText}>{watchDetails.specification.modelNumber}</Typography>
                        </Stack>
                        <Stack direction='column' className={styles.specItemContainer}>
                            <Typography className={styles.specTitle} noWrap>Condition:</Typography>
                            <Typography className={styles.specText}>{watchDetails.specification.condition}</Typography>
                        </Stack>
                        <Stack direction='column' className={styles.specItemContainer}>
                            <Typography className={styles.specTitle} noWrap>Material:</Typography>
                            <Typography className={styles.specText}>{watchDetails.specification.material}</Typography>
                        </Stack>
                        <Stack direction='column' className={styles.specItemContainer}>
                            <Typography className={styles.specTitle} noWrap>Movement:</Typography>
                            <Typography className={styles.specText}>{watchDetails.specification.movement}</Typography>
                        </Stack>
                        <Stack direction='column' className={styles.specItemContainer}>
                            <Typography className={styles.specTitle} noWrap>Lot:</Typography>
                            <Typography className={styles.specText}>{watchDetails.specification.lot}</Typography>
                        </Stack>
                        <Stack direction='column' className={styles.specItemContainer}>
                            <Typography className={styles.specTitle} noWrap>Case:</Typography>
                            <Typography className={styles.specText}>{watchDetails.specification.case}</Typography>
                        </Stack>
                        <Stack direction='column' className={styles.specItemContainer}>
                            <Typography className={styles.specTitle} noWrap>Box:</Typography>
                            <Typography className={styles.specText}>{watchDetails.specification.box}</Typography>
                        </Stack>
                        <Stack direction='column' className={styles.specItemContainer}>
                            <Typography className={styles.specTitle} noWrap>Papers:</Typography>
                            <Typography className={styles.specText}>{watchDetails.specification.papers}</Typography>
                        </Stack>
                        <Stack direction='column' className={styles.specItemContainer}>
                            <Typography className={styles.specTitle} noWrap>Waterproof:</Typography>
                            <Typography className={styles.specText}>{watchDetails.specification.waterproof}</Typography>
                        </Stack>
                    </Stack>
                )}

                <Stack direction='row' className={styles.conditionHeaderContainer}>
                    <Typography className={styles.conditionHeaderText} noWrap>Condition</Typography>
                    <div className={styles.conditionButton} onClick={e => setShowCondition(prev => !prev)}>
                        {showCondition ?
                            <KeyboardArrowDownIcon className={styles.conditionButtonImage}/>
                        :
                            <KeyboardArrowUpIcon className={styles.conditionButtonImage}/>
                        }
                    </div>
                </Stack>                

                <div className={styles.divider}>
                    <div className={styles.dividerItem} />
                </div>

                {showCondition ?
                    watchDetails.conditionDetails.images.length > 0 || watchDetails.conditionDetails.description ? (
                        <Stack direction='column' className={styles.conditionContentContainer}>
                            {watchDetails.conditionDetails.images.length > 0 ? (
                                <div className={styles.otherImagesContainer}>
                                    <LightGallery
                                        licenseKey={LightGalleryLicense}
                                        speed={500}
                                        onInit={onConditionsInit}
                                        plugins={[lgThumbnail, lgZoom]}
                                        getCaptionFromTitleOrAlt={false}
                                        showCloseIcon={true}
                                    >
                                        {watchDetails.conditionDetails.images.map((image, index) => (
                                            <a key={`${watchDetails.id}_${image.mediaUrl}`} href={image.mediaUrl}><img alt="img" src={image.thumbnailUrl} className={index === 0 ? styles.thumbnailImageFirst : styles.thumbnailImage} /></a>
                                        ))}
                                    </LightGallery>
                                    {watchDetails.conditionDetails.images.length > 3 && (
                                        <a href='/' onClick={handleConditionShow}>
                                            <div className={styles.moreContainer} >
                                                <Typography className={styles.moreText}>+{watchDetails.conditionDetails.images.length - 3}</Typography>
                                            </div>
                                        </a>
                                    )}
                                </div>
                            ) : null}

                            {watchDetails.conditionDetails.description ? (
                                <Typography className={styles.conditionText}>{watchDetails.conditionDetails.description}</Typography>
                            ) : null}
                        </Stack>
                    ) : (
                        <Stack direction='row' className={styles.conditionContentContainer}>
                            <Typography className={styles.conditionNoInfo}>No additional condition information</Typography>
                        </Stack>
                    ) 
                : null}

                <Stack direction='column' className={styles.actionsContainer}>
                    {getActionButtons(actions)}
                </Stack>

            </Stack>
        </Stack>
    );
}

export default WatchSummaryMobile;