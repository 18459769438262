import React, { useCallback, useContext, useRef, useState } from "react";
import styles from './Header.module.scss';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/MenuOutlined';
// import SearchIcon from '@mui/icons-material/SearchOutlined';
import {ReactComponent as LogoIcon} from '../../assets/logo.svg';
import menuLogo from '../../assets/menuLogo.png';
import { Button, Stack, Typography } from "@mui/material";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from "../../../constants";

const Header = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
    const isDesktopRef = useRef(isDesktop);
    isDesktopRef.current = isDesktop;
    
    const {pathname, search} = useLocation();
    const returnUrlRef = useRef(pathname + search);
    returnUrlRef.current = pathname + search;
    const [menuOpen, setMenuOpen] = useState(false);
    const {user, login, logOut} = useContext(AuthContext);
    const navigate = useNavigate();

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setMenuOpen(true);
    }, []);

    const handleMenuClose = useCallback(() => {
        setMenuOpen(false);
    }, []);

    const handleLoginClick = useCallback(() => {
        login(!isDesktopRef.current);
    }, [login]);

    const handleLogoutClick = useCallback(() => {
        logOut(!isDesktopRef.current);
    }, [logOut]);

    const handleManageClick = useCallback(() => {
        setMenuOpen(false);
        navigate("/member");
    }, [navigate]);

    const handleLogoClick = useCallback(() => {
        setMenuOpen(false);
        navigate("/");
    }, [navigate]);

    const handleBuyClick = useCallback(() => {
        setMenuOpen(false);
        navigate("/buy");
    }, [navigate]);

    const handleSellClick = useCallback(() => {
        setMenuOpen(false);
        navigate("/sell");
    }, [navigate]);

    const handleShuffleClick = useCallback(() => {
        setMenuOpen(false);
        navigate("/shuffle");
    }, [navigate]);

    const handleAboutUsClick = useCallback(() => {
        setMenuOpen(false);
        navigate("/info#whatiswatchshuffle");
    }, [navigate]);

    const handleContactClick = useCallback(() => {
        setMenuOpen(false);
        navigate("/contact");
    }, [navigate]);

    const handleFaqClick = useCallback(() => {
        setMenuOpen(false);
        navigate("/faq");
    }, [navigate]);

    return (
        <div className={styles.root}>
            <div className={isDesktop ? styles.firstRow: styles.firstRowMobile}>
                <div style={{gridColumn: 1}} className={styles.headerLeft}>
                    <IconButton
                        className={styles.iconButton}
                        aria-label="Menu"
                        onClick={handleMenuClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        anchor='left'
                        open={menuOpen}
                        onClose={handleMenuClose}
                    >
                        <div className={styles.menuContent}>
                            <div className={styles.closeButtonContainer}>
                                <IconButton
                                    className={styles.closeButton}
                                    aria-label="Close"
                                    onClick={handleMenuClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div className={styles.menuLogoContainer}>
                                <img className={styles.menuLogo} src={menuLogo} alt="Watch Shuffle" />
                            </div>
                            <div className={styles.contactContainer}>
                                <Typography className={styles.contact}>
                                    <a href="tel:03003023135">0300 302 3135</a>
                                </Typography>
                                <div className={styles.contactSpacer} />
                                <Typography className={styles.contact}>
                                    <a href="mailto:hello@watchshuffle.com">hello@watchshuffle.com</a>
                                </Typography>
                            </div>
                            <div className={styles.horizontalDividerContainer}>
                                <div className={styles.horizontalDivider} />
                            </div>
                            <div className={styles.menuContainer}>
                                <div className={styles.menuOptionsContainer}>
                                    <TreeView className={styles.menuTree}
                                        aria-label="Menu Options"
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        defaultExpandIcon={<ChevronRightIcon />}>
                                        <TreeItem className={styles.menuOptionHeaderContainer} nodeId="BUY" label={<Typography className={styles.menuOptionHeader}>BUY</Typography>} onClick={handleBuyClick} />
                                        <TreeItem className={styles.menuOptionHeaderContainer} nodeId="SELL" label={<Typography className={styles.menuOptionHeader}>SELL</Typography>} onClick={handleSellClick} />
                                        <TreeItem className={styles.menuOptionHeaderContainer} nodeId="SHUFFLE" label={<Typography className={styles.menuOptionHeader}>SHUFFLE</Typography>} onClick={handleShuffleClick} />
                                        <TreeItem className={styles.menuOptionHeaderContainer} nodeId="ABOUT" label={<Typography className={styles.menuOptionHeader}>ABOUT</Typography>}>
                                            <TreeItem className={styles.menuOptionFirstContainer} nodeId="ABOUT ABOUT US" label={<Typography className={styles.menuOption} onClick={handleAboutUsClick}>ABOUT US</Typography>} />
                                            <TreeItem nodeId="FAQ" label={<Typography className={styles.menuOption} onClick={handleFaqClick}>FAQ</Typography>} />
                                        </TreeItem>
                                        <TreeItem className={styles.menuOptionHeaderContainer} nodeId="CONTACT" label={<Typography className={styles.menuOptionHeader} onClick={handleContactClick}>CONTACT</Typography>} />
                                    </TreeView>
                                </div>
                            </div>
                            <div className={styles.bottomRowContainer}>
                                {user ? (
                                    <Stack direction='row' className={styles.loginButtonContainer}>
                                        <Button className={styles.manageButton} variant="contained" onClick={handleManageClick}>
                                            <Typography className={styles.manageText}>My Account</Typography>
                                        </Button>

                                        <Button className={styles.loginOutButton} variant="contained" onClick={handleLogoutClick}>
                                            <Typography className={styles.logInOutText}>Sign Out</Typography>
                                        </Button>
                                    </Stack>
                                ) : (
                                    <Stack direction='row' className={styles.loginButtonContainer}>
                                        <Button className={styles.loginButton} variant="contained" onClick={handleLoginClick}>
                                            <Typography className={styles.logInOutText}>Login</Typography>
                                        </Button>
                                    </Stack>
                                )}
                            </div>
                        </div>
                    </Drawer>
                </div>

                <div style={{gridColumn: 2}} className={isDesktop ? styles.headerMiddle : styles.headerMiddleMobile}>
                    <IconButton
                        aria-label="Watch Shuffle Home"
                        onClick={handleLogoClick}
                        disableRipple
                    >
                        <LogoIcon className={styles.logoIcon} />
                    </IconButton>
                </div>

                {/* <div style={{gridColumn: 3}} className={styles.headerRight}>
                    <IconButton className={styles.iconButton} onClick={searchClicked}>
                        <SearchIcon />
                    </IconButton>
                </div> */}
            </div>

            {isDesktop && (
                <div className={styles.navContainer}>
                    <NavLink to="/buy">BUY</NavLink>
                    <div className={styles.navSpacer} />
                    <NavLink to="/sell">SELL</NavLink>
                    <div className={styles.navSpacer} />
                    <NavLink to="/shuffle">SHUFFLE</NavLink>
                </div>
            )}
        </div>
    );
}

export default Header;