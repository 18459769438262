import { IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from './PageSelector.module.scss';

export interface PageSelectorProps {    
    maxPages: number;
    pageSelected: number;
    numPagesToDisplay: number;
    onPageChanged: (value: number) => void;
}

export const PageSelector = ({maxPages, pageSelected, numPagesToDisplay, onPageChanged}: PageSelectorProps) => {
    const [pagesToDisplay, setPagesToDisplay] = useState<number[]>([]);
    const [showPrevious, setShowPrevious] = useState(false);
    const [showNext, setShowNext] = useState(false);

    useEffect(() => {
        const pos = (pageSelected - 1) % numPagesToDisplay;
        const display: number[] = [];

        for (let i = 0; i < numPagesToDisplay; i++) {
            const pageNum = pageSelected - (pos - i);
            
            if (pageNum <= maxPages) {
                display.push(pageNum);
            }
        }

        setPagesToDisplay(display);
        setShowPrevious(pageSelected > 1);
        setShowNext(pageSelected < maxPages);
    }, [maxPages, numPagesToDisplay, pageSelected]);
    
    const onNavClicked = useCallback((value: number) => onPageChanged(value), [onPageChanged]);

    return (
        <div className={styles.root}>
            {showPrevious && (
                <IconButton className={styles.navButton} key='showPrevious' onClick={() => onNavClicked(pageSelected-1)} disableRipple>
                    <ChevronLeftIcon className={styles.chevronIcon} />
                </IconButton>
            )}
            {pagesToDisplay.map(page => page === pageSelected ?
                <div className={styles.navSelected} key={page}>
                    <Typography className={styles.statusLabel}>{page}</Typography>
                </div> :
                <IconButton className={styles.navButton} key={page}  onClick={() => onNavClicked(page)} disableRipple>
                    <Typography className={styles.statusLabel}>{page}</Typography>
                </IconButton>
            )}
            {showNext && (
                <IconButton className={styles.navButton} key='showNext' onClick={() => onNavClicked(pageSelected+1)} disableRipple>
                    <ChevronRightIcon className={styles.chevronIcon} />
                </IconButton>
            )}
        </div>
    );
}