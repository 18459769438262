import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ShowroomState {
    statusFilter: string;
    sellerType: string;
    sortBy: string;
    searchText: string;
}

const initialState: ShowroomState = {
    statusFilter: 'ComingSoon',
    sellerType: 'private',
    sortBy: 'endingSoon',
    searchText: '',
}

const showroomSlice = createSlice({
    name: 'showroom',
    initialState,
    reducers: {
        setStatusFilter: (state: ShowroomState, action: PayloadAction<string>) => {
            state.statusFilter = action.payload;
        },
        setSellerType: (state: ShowroomState, action: PayloadAction<string>) => {
            state.sellerType = action.payload;
        },
        setSortBy: (state: ShowroomState, action: PayloadAction<string>) => {
            state.sortBy = action.payload;
        },
        setSearchText: (state: ShowroomState, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
    }
});

const {actions, reducer} = showroomSlice;

export const {setStatusFilter, setSellerType, setSortBy, setSearchText} = actions;
export default reducer;