import { IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../spinner/LoadingSpinner';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './FileUploadItem.module.scss';

export interface FileUploadInfo {
    id: string;
    file: File;
    isLoaded: boolean;
}

export interface FileUploadItemProps {
    fileUploadInfo: FileUploadInfo;
    onUploadComplete: () => void;
    onDelete: (itemToRemove: FileUploadInfo) => void;
}

const formatFileSize = new Intl.NumberFormat('en-GB', {
    maximumFractionDigits: 0
});

const getFileSizeText = (sizeInBytes: number) => {
    if (sizeInBytes < 1024) {
        return `${formatFileSize.format(sizeInBytes)}bytes`;
    }

    const sizeInKb = sizeInBytes / 1024;

    if (sizeInKb < 1024) {
        return `${formatFileSize.format(sizeInKb)}kb`;
    }

    const sizeInMb = sizeInKb / 1024;

    return `${formatFileSize.format(sizeInMb)}mb`;
}

const FileUploadItem = ({ fileUploadInfo, onUploadComplete, onDelete }: FileUploadItemProps) => {
    const [progress, setProgress] = useState(0);
    const [imgSrc, setImgSrc] = useState<string | ArrayBuffer | null>();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const reader = new FileReader();
        
        reader.onload = (e: ProgressEvent<FileReader>) => {
            setImgSrc(e?.target?.result ?? null);
            setProgress(100);
            setIsLoaded(true);
        };

        reader.onprogress = (e: ProgressEvent<FileReader>) => {
            if (e.lengthComputable) {    
                const percent = (e.loaded / e.total) * 100;                
                const percentRounded = parseInt(percent.toString());
                setProgress(percentRounded);
            }
        }

        reader.readAsDataURL(fileUploadInfo.file);
    }, [fileUploadInfo.file]);

    return (
        <div className={styles.root}>
            <div className={styles.imageContainer}>
                {isLoaded ? (
                    <img className={styles.loadedImage}
                        alt={`img - ${fileUploadInfo.id}`}
                        src={typeof imgSrc === 'string' ? imgSrc : ''}
                    />
                ) : (
                    <LoadingSpinner />
                )}
            </div>
            <div className={styles.fileInfoContainer}>
                <Typography className={styles.fileNameText}>{fileUploadInfo.file.name}</Typography>
                <Typography className={styles.fileSizeText}>{getFileSizeText(fileUploadInfo.file.size)}&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;{isLoaded ? 'Successfully uploaded' : 'Uploading...'}</Typography>

                <div className={styles.progressBarContainer}>
                    <div className={styles.progressBar}>
                        <div className={styles.doneBar} style={{width: `${progress}%`}} />
                        <div className={styles.remainingBar} style={{width: `${100 - progress}%`}} />
                    </div>

                </div>
            </div>
            <div className={styles.clearButtonContainer}>
                <IconButton onClick={() => onDelete(fileUploadInfo)}>
                    <ClearIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default FileUploadItem;