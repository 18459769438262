import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styles from './YourListings.module.scss';
import WatchSummary from "./WatchSummary";
import { loggingService } from "../../../../utils/logging/logging";
import { getWatchesForSeller } from "../../../services/auction-service";
import { SnackContext } from "../../../providers/SnackProvider";
import { WatchInfoBuyDetails } from "../../../models/WatchInfoBuyDetails";
import WatchSummaryMobile from "./WatchSummaryMobile";
import { MobileScreenWidth } from "../../../../constants";
import { useMediaQuery } from "react-responsive";

const {log} = loggingService('app:YourListings');
type StatusKey = 'awaiting'|'pending'|'live'|'inactive';
const defaultStatusToItemsMap: Record<StatusKey, WatchInfoBuyDetails[]> = {
    awaiting: [],
    pending: [],
    live: [],
    inactive: []
};

const YourListings = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
    const showSnackbar = useContext(SnackContext);
    const [selectedOption, setSelectedOption] = useState<StatusKey>('awaiting');
    const [statusToItemsMap, setStatusToItemsMap] = useState<Record<StatusKey, WatchInfoBuyDetails[]>>(defaultStatusToItemsMap);

    const handleMenuOptionClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>, option: StatusKey) => {
        e.preventDefault();
        setSelectedOption(option);
    }, []);

    const onFilterChanged = useCallback((event: SelectChangeEvent) => {
        setSelectedOption(event.target.value as StatusKey);
    }, []);

    const statusClasses = useMemo(() => {
        return {
            standard: styles.statusDropdown,
            select: styles.statusDropdownSelected
        };
    }, []);

    const loadWatches = useCallback(async () => {
        try {
            log('getting watches for seller');
            const items = await getWatchesForSeller();
            log('getting watches for seller result', items);
            
            const initial: Record<StatusKey, WatchInfoBuyDetails[]> = {
                awaiting: [],
                pending: [],
                live: [],
                inactive: []
            };

            const statusMapping = items.reduce((agg, item) => {
                switch (item.status) {
                    case 'ApprovalRequested': {
                        agg.awaiting.push(item);
                        break;
                    }
                    case 'Accepted':
                    case 'ComingSoon': {
                        agg.pending.push(item);
                        break;
                    }
                    case 'Live': {
                        agg.live.push(item);
                        break;
                    }
                    case 'Rejected':
                    case 'Withdrawn':
                    case 'EndedSold':
                    case 'EndedNotSold': {
                        agg.inactive.push(item);
                        break;
                    }
                }
                
                return agg;
            }, initial);
            
            // Sort the items in each category by the created time 
            Object.values(statusMapping).forEach(items => {
                items.sort((x,y) => x.createdOn === y.createdOn ? 0 : (x.createdOn > y.createdOn ? 1 : -1));
            });

            setStatusToItemsMap(statusMapping);
        } catch (error) {
            log('Error retrieving watches for seller', error);
            showSnackbar('An error occurred loading the watch listings. Please try again later.', {alertSeverity: 'error'});
        }
    }, [showSnackbar]);

    const refreshListings = useCallback(() => {
        loadWatches();
    }, [loadWatches]);

    useEffect(() => {
        loadWatches();
    }, [loadWatches]);

    return isDesktop ? (
        <div className={styles.root}>
            <Stack direction='column' style={{gridColumn: 1}} className={styles.leftContainer}>
                <Stack direction='row' className={selectedOption === 'awaiting' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'awaiting')}>Awaiting Approval</a>
                    </Typography>
                    <Stack direction='row' className={styles.awaitingContainer}>
                        <Typography className={styles.countLabel}>{statusToItemsMap?.awaiting?.length ?? 0}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' className={selectedOption === 'pending' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'pending')}>Pending</a>
                    </Typography>
                    <Stack direction='row' className={styles.pendingContainer}>
                        <Typography className={styles.countLabel}>{statusToItemsMap?.pending?.length ?? 0}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' className={selectedOption === 'live' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'live')}>Live</a>
                    </Typography>
                    <Stack direction='row' className={styles.liveContainer}>
                        <Typography className={styles.countLabel}>{statusToItemsMap?.live?.length ?? 0}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' className={selectedOption === 'inactive' ? styles.menuOptionSelected : styles.menuOption}>
                    <Typography className={styles.menuLink} noWrap>
                        <a href='/' onClick={e => handleMenuOptionClick(e, 'inactive')}>In-Active</a>
                    </Typography>
                    <Stack direction='row' className={styles.inactiveContainer}>
                        <Typography className={styles.countLabel}>{statusToItemsMap?.inactive?.length ?? 0}</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack direction='column' style={{gridColumn: 2}}>
                {statusToItemsMap?.[selectedOption]?.length ?
                    statusToItemsMap?.[selectedOption]?.map(watchDetails =>(
                        <WatchSummary key={watchDetails.id} watchDetails={watchDetails} refreshListings={refreshListings} />
                    )) : (
                        <Stack key='No watches found' className={styles.noItemsContainer} direction='column'>
                            <Typography className={styles.noItemsLabel}>No watches found.</Typography>
                        </Stack>
                    )
                }
            </Stack>
        </div>
    ) : (
        <div className={styles.rootMobile}>
            <div className={styles.statusFilterContainerMobile}>
                <Select classes={statusClasses} variant="standard" value={selectedOption} onChange={onFilterChanged}>
                    <MenuItem value={'awaiting'}>
                        <Stack direction='row'>
                            <Typography className={styles.statusText}>Awaiting Approval</Typography>
                            <Stack direction='row' className={styles.awaitingContainerMobile}>
                                <Typography className={styles.countLabelMobile}>{statusToItemsMap?.awaiting?.length ?? 0}</Typography>
                            </Stack>
                        </Stack>
                    </MenuItem>
                    <MenuItem value={'pending'}>
                        <Stack direction='row'>
                            <Typography className={styles.statusText}>Pending</Typography>
                            <Stack direction='row' className={styles.pendingContainerMobile}>
                                <Typography className={styles.countLabelMobile}>{statusToItemsMap?.pending?.length ?? 0}</Typography>
                            </Stack>
                        </Stack>
                    </MenuItem>
                    <MenuItem value={'live'}>
                        <Stack direction='row'>
                            <Typography className={styles.statusText}>Live</Typography>
                            <Stack direction='row' className={styles.liveContainerMobile}>
                                <Typography className={styles.countLabelMobile}>{statusToItemsMap?.live?.length ?? 0}</Typography>
                            </Stack>
                        </Stack>
                    </MenuItem>
                    <MenuItem value={'inactive'}>
                        <Stack direction='row'>
                            <Typography className={styles.statusText}>In-Active</Typography>
                            <Stack direction='row' className={styles.inactiveContainerMobile}>
                                <Typography className={styles.countLabelMobile}>{statusToItemsMap?.inactive?.length ?? 0}</Typography>
                            </Stack>
                        </Stack>
                    </MenuItem>
                </Select>   
            </div>            
            
            <Stack direction='column'>
                {statusToItemsMap?.[selectedOption]?.length ?
                    statusToItemsMap?.[selectedOption]?.map(watchDetails =>(
                        <WatchSummaryMobile key={watchDetails.id} watchDetails={watchDetails} refreshListings={refreshListings} />
                    )) : (
                        <Stack className={styles.noItemsContainer} direction='column'>
                            <Typography className={styles.noItemsLabel}>No watches found.</Typography>
                        </Stack>
                    )
                }
            </Stack>            
        </div>
    );
}

export default YourListings;