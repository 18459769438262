import { IconButton, Stack, Typography } from "@mui/material";
import { WatchInfoBuyDetails } from "../../../../models/WatchInfoBuyDetails";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import styles from "./AuctionItemMobile.module.scss";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ConditionTooltip from "../../../conditionTooltip/ConditionTooltip";
import NoPhotographyOutlinedIcon from "@mui/icons-material/NoPhotographyOutlined";
import EndTimer from "../../../endTimer/EndTimer";
import { getDisplayDate } from "../../../../../utils/date/dateUtils";

export interface AuctionItemProps {
    info: WatchInfoBuyDetails;
    onViewDetails?: (info: WatchInfoBuyDetails) => void;
    onFavouritesToggle?: (info: WatchInfoBuyDetails) => void;
    onCountdownFinished?: () => void;
    show?: boolean;
}

const AuctionItemMobile = ({
    info,
    onViewDetails,
    onFavouritesToggle,
    onCountdownFinished,
    show,
}: AuctionItemProps) => {
    const navigate = useNavigate();

    const handleFavouriteToggle = useCallback(
        async (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            if (onFavouritesToggle) onFavouritesToggle(info);
            else return;
        },
        [info, onFavouritesToggle],
    );

    const handleViewDetailsClick = useCallback(() => {
        navigate(`/details?id=${info.id}`);
    }, [info.id, navigate]);

    return (
        <div className={styles.root} onClick={handleViewDetailsClick}>
            <div className={styles.contentContainer}>
                <div className={styles.brandContainer}>
                    <Typography className={styles.brandTitle}>
                        {info.brand}
                    </Typography>
                    {show ? (
                        <div className={styles.favouriteContainer}>
                            <IconButton
                                aria-label={
                                    info.isFavourite
                                        ? "Remove from favourites"
                                        : "Add to favourites"
                                }
                                onClick={handleFavouriteToggle}
                            >
                                {info.isFavourite ? (
                                    <FavoriteIcon
                                        className={styles.favouriteIcon}
                                    />
                                ) : (
                                    <FavoriteBorderIcon
                                        className={styles.favouriteIcon}
                                    />
                                )}
                            </IconButton>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div className={styles.modelContainer}>
                    <Typography className={styles.modelTitle} noWrap>
                        {info.model}
                    </Typography>
                </div>

                <div className={styles.yearContainer}>
                    <Typography className={styles.yearTitle}>
                        {info.year}
                    </Typography>
                </div>

                <div className={styles.watchImageContainer}>
                    {info.showroomImage?.thumbnailUrl ? (
                        <img
                            className={styles.watchImage}
                            src={info.showroomImage.thumbnailUrl}
                            alt=""
                        />
                    ) : (
                        <Stack
                            direction="column"
                            className={styles.watchNoImageContainer}
                        >
                            <NoPhotographyOutlinedIcon />
                            <Typography className={styles.watchNoImageText}>
                                Image Unavailable
                            </Typography>
                        </Stack>
                    )}
                </div>

                <Stack direction="row" className={styles.infoContainer}>
                    <Stack
                        direction="column"
                        className={styles.titleAndValueContainer}
                    >
                        <Typography className={styles.titleLabel}>
                            Condition
                        </Typography>
                        <Stack
                            direction="row"
                            className={styles.valueContainer}
                        >
                            <Typography className={styles.valueLabel}>
                                {info.specification.condition}
                            </Typography>
                            <ConditionTooltip isLarge={false} />
                        </Stack>

                        {(info.status === "Live" ||
                            info.status === "EndedSold") && (
                            <Stack
                                direction="column"
                                className={styles.bidsContainer}
                            >
                                <Typography className={styles.titleLabel}>
                                    Bids
                                </Typography>
                                <Stack
                                    direction="row"
                                    className={styles.valueContainer}
                                >
                                    <Typography className={styles.valueLabel}>
                                        {info.bids}
                                    </Typography>
                                </Stack>
                            </Stack>
                        )}
                    </Stack>

                    <div className={styles.spacer} />

                    <Stack
                        direction="column"
                        className={styles.titleAndValueContainer}
                    >
                        {info.status === "ComingSoon" && (
                            <>
                                <Typography className={styles.titleLabel}>
                                    {info.auctionStartDate ||
                                    info.auctionStartDateDescription
                                        ? "Auction Starts"
                                        : "Auction Ends"}
                                </Typography>
                                <Stack
                                    direction="row"
                                    className={styles.valueContainer}
                                >
                                    <Typography className={styles.valueLabel}>
                                        {info.auctionStartDate ||
                                        info.auctionStartDateDescription
                                            ? getDisplayDate(
                                                  info.auctionStartDate,
                                                  info.auctionStartDateDescription,
                                              )
                                            : getDisplayDate(
                                                  info.auctionEndDate,
                                                  info.auctionEndDateDescription,
                                              )}
                                    </Typography>
                                </Stack>
                            </>
                        )}

                        {info.status === "Live" && (
                            <>
                                <Typography className={styles.titleLabel}>
                                    Auction Ends
                                </Typography>
                                <EndTimer
                                    endDate={info.auctionEndDate}
                                    endDateDescription={
                                        info.auctionEndDateDescription
                                    }
                                    isLarge={false}
                                    onCountdownFinished={onCountdownFinished}
                                />
                            </>
                        )}

                        {info.status === "EndedSold" && (
                            <>
                                <Typography className={styles.titleLabel}>
                                    Auction Ended
                                </Typography>
                                <Stack
                                    direction="row"
                                    className={styles.valueContainer}
                                >
                                    <Typography className={styles.valueLabel}>
                                        {getDisplayDate(
                                            info.auctionEndDate,
                                            info.auctionEndDateDescription,
                                        )}
                                    </Typography>
                                </Stack>
                            </>
                        )}

                        {info.status === "Live" && (
                            <Stack
                                direction="column"
                                className={styles.currentBidContainer}
                            >
                                <Typography className={styles.titleLabel}>
                                    Current Bid
                                </Typography>
                                <Stack
                                    direction="row"
                                    className={styles.valueContainer}
                                >
                                    {info.currentBid ? (
                                        <Typography
                                            className={styles.valueLabel}
                                        >{`£${info.currentBid.toLocaleString()}`}</Typography>
                                    ) : (
                                        <Typography
                                            className={styles.noBidsText}
                                        >
                                            No bids
                                        </Typography>
                                    )}
                                </Stack>
                            </Stack>
                        )}

                        {info.status === "EndedSold" && (
                            <Stack
                                direction="column"
                                className={styles.currentBidContainer}
                            >
                                <Typography className={styles.titleLabel}>
                                    Winning Bid
                                </Typography>
                                <Stack
                                    direction="row"
                                    className={styles.valueContainer}
                                >
                                    {info.currentBid ? (
                                        <Typography
                                            className={styles.valueLabel}
                                        >{`£${info.currentBid.toLocaleString()}`}</Typography>
                                    ) : (
                                        <Typography
                                            className={styles.noBidsText}
                                        >
                                            No bids
                                        </Typography>
                                    )}
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </div>
        </div>
    );
};

export default AuctionItemMobile;
