import { Button, Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography } from "@mui/material";
import styles from './PersonalDetails.module.scss';
import { useCallback, useContext, useRef } from "react";
import { AuthContext } from "../../../providers/AuthProvider";
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from "../../../../constants";
import StripeSummary from "./StripeSummary";

const PersonalDetails = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
    const isDesktopRef = useRef(isDesktop);
    isDesktopRef.current = isDesktop;
        
    const {user, manageAccount} = useContext(AuthContext);

    const handleManageAccountClicked = useCallback(() => {
        manageAccount(!isDesktopRef.current);
    }, [manageAccount]);

    return (
        <div className={isDesktop ? styles.root : styles.rootMobile}>
            <Stack direction='column' style={{gridColumn: 1}} className={isDesktop ? styles.leftContainer : ''}>
                <div className={isDesktop ? styles.nameContainer : styles.nameContainerMobile}>
                    <Stack direction='column' className={styles.textContainer}>
                        <Typography className={styles.welcomeText}>WELCOME BACK</Typography>
                        <Typography className={styles.nameText}>{user?.public_display_name}</Typography>
                        <div className={styles.divider} />
                    </Stack>
                </div>

                {!isDesktop && (
                    <Stack direction='column' className={styles.cardDetailsContainerMobile}>
                        <StripeSummary />
                    </Stack>
                )}

                <Stack direction='column' className={isDesktop ? styles.accountSettingsContainer : styles.accountSettingsContainerMobile}>
                    <Typography className={styles.header}>ACCOUNT SETTINGS</Typography>
                    <TextField
                        className={styles.inputField}
                        fullWidth
                        id="displayNameInput"
                        label="Display Name"
                        value={user?.public_display_name}
                        variant="standard"
                    />
                    <TextField
                        className={styles.inputField}
                        fullWidth
                        id="usernameInput"
                        label="Username"
                        value={user?.username}
                        variant="standard"
                    />
                    <TextField
                        className={styles.inputField}
                        fullWidth
                        id="emailInput"
                        label="Email"
                        value={user?.email}
                        variant="standard"                    
                    />
                    <TextField
                        className={styles.inputField}
                        fullWidth
                        id="phoneInput"
                        label="Phone"
                        value={user?.phone_number}
                        variant="standard"                    
                    />
                    <Stack direction='row' className={styles.verifiedContainer}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox className={styles.verifiedInput} checked={user?.email_verified ?? false} readOnly disableRipple />} label={<Typography className={styles.verifiedText}>Email Address Verified</Typography>} />
                        </FormGroup>
                    </Stack>
                    <Stack direction='row' className={styles.manageContainer}>
                        <Button className={styles.manageButton} variant="contained" disableElevation onClick={handleManageAccountClicked}>
                            <Typography className={styles.manageText}>Manage Account</Typography>
                        </Button>
                    </Stack>
                </Stack>
            </Stack>

            {isDesktop && (
                <Stack direction='column' style={{gridColumn: 2}} className={styles.rightContainer}>
                    <StripeSummary />
                </Stack>
            )}
        </div>
    );
}

export default PersonalDetails;