import {
    IconButton,
    Stack,
    Typography,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState, useCallback, useContext, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { WatchInfoBuyDetails } from "../../../models/WatchInfoBuyDetails";
import AuctionItem from "../auction/buy/AuctionItem";
import AuctionItemMobile from "../auction/buy/AuctionItemMobile";
import styles from "./Favourites.module.scss";
import {
    watchUnwatch,
    getFavWatchesByStatus,
} from "../../../services/auction-service";
import { SnackContext } from "../../../providers/SnackProvider";
import { MobileScreenWidth } from "../../../../constants";

const initialStatusToCount = {
    ComingSoon: 0,
    Live: 0,
    EndedSold: 0,
};

const Favourites = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    const showSnackbar = useContext(SnackContext);
    const [statusToCount, setStatusToCount] =
        useState<Record<string, number>>(initialStatusToCount);
    const [selectedOption, setSelectedOption] = useState("Live");
    const [auctionItems, setAuctionItems] = useState<WatchInfoBuyDetails[]>([]);

    const statusClasses = useMemo(() => {
        return {
            standard: styles.statusDropdown,
            select: styles.statusDropdownSelected,
        };
    }, []);

    const loadWatches = useCallback(
        async (status: string) => {
            try {
                const items = await getFavWatchesByStatus(status);
                setAuctionItems(items);
                setStatusToCount((prev) => {
                    const newVal = { ...prev };
                    newVal[status] = items.length;
                    return newVal;
                });
            } catch (error) {
                showSnackbar(
                    "An error occurred loading the watch listings. Please try again later.",
                    { alertSeverity: "error" },
                );
                setAuctionItems([]);
                setStatusToCount(initialStatusToCount);
            }
        },
        [showSnackbar],
    );

    useEffect(() => {
        loadWatches(selectedOption);
    }, [loadWatches, selectedOption]);

    useEffect(() => {
        setStatusToCount((prev) => {
            const newVal = { ...prev };
            newVal[selectedOption] = auctionItems.length;
            return newVal;
        });
    }, [auctionItems, selectedOption]);

    const handleMenuOptionClick = (
        e: React.MouseEvent<HTMLElement>,
        option: string,
    ) => {
        e.preventDefault();
        setSelectedOption(option);
    };

    const onFilterChanged = useCallback((event: SelectChangeEvent) => {
        setSelectedOption(event.target.value);
    }, []);

    const updateItems = useCallback(
        (id: string, value: boolean) => {
            setAuctionItems((prev: any) => {
                return prev.filter((item: any) => item.id !== id);
            });
        },
        [setAuctionItems],
    );

    const onFavouritesToggle = useCallback(
        async (info: WatchInfoBuyDetails) => {
            try {
                await watchUnwatch(info.id, {
                    watching: !info.isFavourite,
                });
                updateItems(info.id, !info.isFavourite);
            } catch (error: any) {
                showSnackbar(error.message, { alertSeverity: "error" });
            }
        },
        [showSnackbar, updateItems],
    );

    return isDesktop ? (
        <div className={styles.root}>
            <Stack
                direction="column"
                style={{ gridColumn: 1 }}
                className={styles.leftContainer}
            >
                <IconButton
                    disableFocusRipple
                    disableRipple
                    onClick={(e) => handleMenuOptionClick(e, "Live")}
                    className={
                        selectedOption === "Live"
                            ? styles.menuOptionSelected
                            : styles.menuOption
                    }
                >
                    <Typography className={styles.menuLink} noWrap>
                        Live
                    </Typography>
                    <Stack
                        direction="row"
                        className={
                            selectedOption === "Live"
                                ? styles.activeContainer
                                : styles.inactiveContainer
                        }
                    >
                        <Typography className={styles.countLabel}>
                            {statusToCount.Live}
                        </Typography>
                    </Stack>
                </IconButton>
                <IconButton
                    disableFocusRipple
                    disableRipple
                    onClick={(e) => handleMenuOptionClick(e, "ComingSoon")}
                    className={
                        selectedOption === "ComingSoon"
                            ? styles.menuOptionSelected
                            : styles.menuOption
                    }
                >
                    <Typography className={styles.menuLink} noWrap>
                        Coming Soon
                    </Typography>
                    <Stack
                        direction="row"
                        className={
                            selectedOption === "ComingSoon"
                                ? styles.activeContainer
                                : styles.inactiveContainer
                        }
                    >
                        <Typography className={styles.countLabel}>
                            {statusToCount.ComingSoon}
                        </Typography>
                    </Stack>
                </IconButton>
                <IconButton
                    disableFocusRipple
                    disableRipple
                    onClick={(e) => handleMenuOptionClick(e, "EndedSold")}
                    className={
                        selectedOption === "EndedSold"
                            ? styles.menuOptionSelected
                            : styles.menuOption
                    }
                >
                    <Typography className={styles.menuLink} noWrap>
                        Sold
                    </Typography>
                    <Stack
                        direction="row"
                        className={
                            selectedOption === "EndedSold"
                                ? styles.activeContainer
                                : styles.inactiveContainer
                        }
                    >
                        <Typography className={styles.countLabel}>
                            {statusToCount.EndedSold}
                        </Typography>
                    </Stack>
                </IconButton>
            </Stack>

            <Stack
                direction="row"
                style={{ gridColumn: 2 }}
                className={styles.itemsContainer}
            >
                {auctionItems.map((x) => (
                    <AuctionItem
                        key={x.id}
                        info={x}
                        onFavouritesToggle={onFavouritesToggle}
                    />
                ))}
            </Stack>
        </div>
    ) : (
        <div className={styles.rootMobile}>
            <div className={styles.statusFilterContainerMobile}>
                <Select
                    classes={statusClasses}
                    variant="standard"
                    value={selectedOption}
                    onChange={onFilterChanged}
                >
                    <MenuItem value={"Live"}>
                        <Stack direction="row">
                            <Typography
                                className={styles.statusText}
                                style={{ marginRight: "8px" }}
                            >
                                Live
                            </Typography>
                            <Stack
                                direction="row"
                                className={
                                    selectedOption === "Live"
                                        ? styles.menuOptionSelectedMobile
                                        : styles.menuOptionMobile
                                }
                            >
                                <Typography className={styles.countLabelMobile}>
                                    {statusToCount.Live}
                                </Typography>
                            </Stack>
                        </Stack>
                    </MenuItem>
                    <MenuItem value={"ComingSoon"}>
                        <Stack direction="row">
                            <Typography
                                className={styles.statusText}
                                style={{ marginRight: "8px" }}
                            >
                                Coming Soon
                            </Typography>
                            <Stack
                                direction="row"
                                className={
                                    selectedOption === "ComingSoon"
                                        ? styles.menuOptionSelectedMobile
                                        : styles.menuOptionMobile
                                }
                            >
                                <Typography className={styles.countLabelMobile}>
                                    {statusToCount.ComingSoon}
                                </Typography>
                            </Stack>
                        </Stack>
                    </MenuItem>
                    <MenuItem value={"EndedSold"}>
                        <Stack direction="row">
                            <Typography
                                className={styles.statusText}
                                style={{ marginRight: "8px" }}
                            >
                                Sold
                            </Typography>
                            <Stack
                                direction="row"
                                className={
                                    selectedOption === "EndedSold"
                                        ? styles.menuOptionSelectedMobile
                                        : styles.menuOptionMobile
                                }
                            >
                                <Typography className={styles.countLabelMobile}>
                                    {statusToCount.EndedSold}
                                </Typography>
                            </Stack>
                        </Stack>
                    </MenuItem>
                </Select>
            </div>
            <div className={styles.itemsContainerMobile}>
                <div className={styles.itemsContainerInner}>
                    {auctionItems.map((x) => (
                        <AuctionItemMobile
                            key={x.id}
                            info={x}
                            onFavouritesToggle={onFavouritesToggle}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Favourites;
