import { Alert, AlertColor, Box, Button, ButtonProps, Grow, Snackbar, Stack, Typography } from "@mui/material";
import { ComponentProps, PropsWithChildren, createContext, useCallback, useMemo, useRef, useState } from "react";
import _ from 'lodash';
import styles from './SnackProvider.module.scss';

export interface SnackArgs {
    alertSeverity?: AlertColor;
    autoHideDuration?: number;
    autoHide?: boolean;
    onClose?: () => void;
    actions?: (ButtonProps & {label: string})[];
}

export interface Snack {
    updateSnackbar?: (message: string, args?: SnackArgs) => void;
    closeSnackbar: () => void;
    message: string;
    args: SnackArgs;
}

export type RaiseSnackbarFunction = (message: string, args?: SnackArgs) => void;

const DEFAULT_AUTO_HIDE_MS = 5_000;
const defaultSnack: Snack = {
    closeSnackbar: () => {},
    message: '',
    args: {}
};

export const SnackContext = createContext<RaiseSnackbarFunction>(() => defaultSnack);

export const SnackProvider = ({children}: PropsWithChildren) => {
    const [snacks, setSnacks] = useState<Snack[]>([]);
    const snacksRef = useRef(snacks);
    snacksRef.current = snacks;
    
    const snackController = useMemo<RaiseSnackbarFunction>(() =>
        (message, args) => {
            const snack: Snack = {
                message,
                args: {
                    ...args
                },
                closeSnackbar: () => {
                    setSnacks(_.without(snacksRef.current, snack));
                    snack.args?.onClose?.();
                },
                updateSnackbar: (message: string, args?: SnackArgs) => {
                    const index = snacksRef.current.indexOf(snack);

                    if (index >= 0) {
                        snacksRef.current[index].message = message;
                        
                        if (args) {
                            snacksRef.current[index].args = args;
                        }

                        setSnacks([...snacksRef.current]);
                    }
                }
            };

            if (args?.autoHide === undefined || args?.autoHide) {
                setTimeout(() => {
                    snack.closeSnackbar();
                }, args?.autoHideDuration ?? DEFAULT_AUTO_HIDE_MS);
            }

            setSnacks(snacksRef.current.concat(snack));
            return snack;
        }
    , []);

    const onSnackbarClose: ComponentProps<typeof Snackbar>['onClose'] = (_event, reason) => {
        // Stops the snackbar disappearing when clicking elsewhere on the screen
        if (reason === 'clickaway') {
            return;
        }
    };

    const onAlertClose = useCallback((snack: Snack) => {
        snack.closeSnackbar();
    }, []);

    return (
        <SnackContext.Provider value={snackController}>
            <>
                {children}
                <Snackbar 
                    anchorOrigin={{
                        vertical: 'bottom', horizontal: 'center'
                    }}
                    open={!!snacks.length}
                    TransitionComponent={Grow}
                    className={styles.snackbar}
                    onClose={onSnackbarClose}>
                    <Box>
                        <Stack direction='column' gap='1rem' className={styles.paperContainer}>
                            {snacks.map((snack, index) =>
                                <Alert 
                                    key={`Alert${index}`} 
                                    severity={snack.args?.alertSeverity || 'info'}
                                    onClose={(_event) => onAlertClose(snack)}
                                    sx={{width: 'max-content', alignSelf: 'center', alignItems: 'center'}}
                                    className={styles.alertContainer}
                                    >
                                    <Stack direction='row' alignItems='center' gap='1rem'>
                                        <Typography className={styles.alertMessage}>{snack.message}</Typography>
                                        {snack.args?.actions?.length && snack.args.actions.map(({label, ...buttonProps}, btnIndex) => 
                                            <Button key={`Alert${index}_Button${btnIndex}`} {...buttonProps}>{label}</Button>
                                        )}
                                    </Stack>
                                </Alert>
                            )}
                        </Stack>
                    </Box>
                </Snackbar>                    
            </>
        </SnackContext.Provider>
    );
}