import { createContext, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { useFetchWrapper } from "../hooks/useFetchWrapper";
import { loggingService } from "../../utils/logging/logging";
import { useLocation } from "react-router";

const {log} = loggingService('app:AuthProvider');

export interface UserDetails {
    user_id: string;
    given_name: string;
    family_name: string;
    subscribe_to_newsletter: boolean;
    public_display_name: string;
    name: string;
    email: string;
    email_verified: boolean;
    username: string;
    phone_number?: string;
    phone_number_verified: boolean;
    role: string[];
    payment_method_attached: boolean;
}

export interface AuthApi {
    user?: UserDetails;
    refresh: () => void;
    login: (mobile: boolean) => void;
    logOut: (mobile: boolean) => void;
    manageAccount: (mobile: boolean) => void;
}

const defaultAuthApi: AuthApi = {
    refresh: () => {},
    login: (mobile: boolean) => {},
    logOut: (mobile: boolean) => {},
    manageAccount: (mobile: boolean) => {},
};
export const AuthContext = createContext<AuthApi>(defaultAuthApi);

export const AuthProvider = ({children}: PropsWithChildren) => {
    const {get} = useFetchWrapper();
    const {pathname, search} = useLocation();
    const returnUrlRef = useRef(pathname + search);
    returnUrlRef.current = pathname + search;
    const [userDetails, setUserDetails] = useState<UserDetails>();
    const [authApi, setAuthApi] = useState<AuthApi>(defaultAuthApi);
    const [isLoading, setIsLoading] = useState(true);
    
    const getUserDetails = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await get('/dax-api/connect/loggedInUserDetails');
            log('Response from fetching user info', response);
            const user: UserDetails = (await response.json());
            log('userDetails from fetching user info', user);

            setUserDetails(user);
        } catch (error) {
            log('Error fetching user info', error);
            setUserDetails(undefined);
        }
        setIsLoading(false);
    }, [get]);

    const login = useCallback((mobile: boolean) => {
        log ('Log out called');
        window.location.replace(`/Identity/Account/Login?layout=wsm&returnUrl=${returnUrlRef.current}`);
    }, []);

    const logOut = useCallback((mobile: boolean) => {
        log ('Log out called');
        window.location.replace(`/Identity/Account/Logout?layout=wsm&returnUrl=${returnUrlRef.current}`);
    }, []);

    const manageAccount = useCallback((mobile: boolean) => {
        log ('Manage Account called');
        window.location.replace(`/Identity/Account/Manage?layout=wsm&returnUrl=${returnUrlRef.current}`);
    }, []);

    const refresh = useCallback(() => {
        getUserDetails();
    }, [getUserDetails]);

    useEffect(() => {
        getUserDetails();
    }, [getUserDetails]);

    useEffect(() => {
        setAuthApi({
            user: userDetails,
            refresh,
            login,
            logOut,
            manageAccount
        });
    }, [login, logOut, manageAccount, refresh, userDetails]);

    return isLoading ? (
        <></>
    ) : (
        <AuthContext.Provider value={authApi}>{children}</AuthContext.Provider>
    );
}