import { WatchInfoBuyDetails } from "../../../models/WatchInfoBuyDetails";
import { UserDetails } from "../../../providers/AuthProvider";
import { approveListingForSeller, requestEditListingForSeller } from "../../../services/auction-service";

export interface ActionItem {
    id: string;
    name: string;
    action: () => Promise<boolean>;
};

const handleRequestEdit = (watchDetails: WatchInfoBuyDetails) => {
    return async () => {
        await requestEditListingForSeller(watchDetails.id);
        return true;
    };
}

const handleApproveListing = (watchDetails: WatchInfoBuyDetails) => {
    return async () => {
        await approveListingForSeller(watchDetails.id);
        return true;
    };
}

const handleContactBuyer = (watchDetails: WatchInfoBuyDetails) => {
    return async () => {
        return false;
    };
}

const handleContactSeller = (watchDetails: WatchInfoBuyDetails) => {
    return async () => {
        return false;
    };
}

const handleViewDetailsClick = (watchDetails: WatchInfoBuyDetails, navigate: any) => {
    return async () => {
        navigate(`/details?id=${watchDetails.id}`);
        return false;
    };
}

export const getActions = (watchDetails: WatchInfoBuyDetails, user: UserDetails | undefined, navigate: any): ActionItem[] => {
    const availableActions: ActionItem[] = [];

    if (watchDetails.status === 'ApprovalRequested' && user?.user_id && watchDetails.sellerId === user.user_id) {
        availableActions.push({
            id: `${watchDetails.id}_handleApproveListing`,
            name: 'Approve Listing',
            action: handleApproveListing(watchDetails)
        });
        availableActions.push({
            id: `${watchDetails.id}_handleRequestEdit`,
            name: 'Request Changes',
            action: handleRequestEdit(watchDetails)
        });
    }

    if (watchDetails.status === 'EndedSold' && user?.user_id && user?.user_id === watchDetails.sellerId) {
        availableActions.push({
            id: `${watchDetails.id}_handleContactBuyer`,
            name: 'Contact Buyer',
            action: handleContactBuyer(watchDetails)
        });
    }

    if (watchDetails.status === 'EndedSold' && user?.user_id && watchDetails.winningBid && user?.user_id === watchDetails.winningBid?.user) {
        availableActions.push({
            id: `${watchDetails.id}_handleContactSeller`,
            name: 'Contact Seller',
            action: handleContactSeller(watchDetails)
        });
    }

    if (watchDetails.status === 'EndedSold' && user?.user_id && watchDetails.sellerId === user.user_id) {
        availableActions.push({
            id: `${watchDetails.id}_handleContactBuyer`,
            name: 'Contact Buyer',
            action: handleContactBuyer(watchDetails)
        });
    }

    if (!user?.role.includes('Tenant Admin')) {
        availableActions.push({
            id: `${watchDetails.id}_handleViewListing`,
            name: 'View Details',
            action: handleViewDetailsClick(watchDetails, navigate)
        });
    }

    return availableActions;
}