const logWithId = (id: string, ...args: any[]) => {
    console.log(...[`${id} - `, ...args]);
}

export const loggingService = (context: string) => {
    return {
        log: (...args: any[]) => {
            logWithId(context, ...args);
        }
    }
}