import { Typography } from "@mui/material";
import cuid from "cuid";
import React, { useCallback } from "react";
import { Accept } from "react-dropzone";
import Dropzone from "./Dropzone";
import styles from "./FileUploadComponent.module.scss";
import FileUploadItem, { FileUploadInfo } from "./FileUploadItem";


export interface FileUploadComponentProps {
    disabled: boolean;
    setFiles: React.Dispatch<React.SetStateAction<any>>;
    files: FileUploadInfo[];
    maxFiles?: number;
}

const FileUploadComponent = ({ disabled, setFiles, files, maxFiles }: FileUploadComponentProps) => {
    const acceptImages: Accept = {
        "image/gif": [".gif"],
        "image/jpg": [".jpg", ".jpeg", ".jpe", ".jfif"],
        "image/png": [".png"],
        "image/svg": [".svg"],
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setFiles((prev: FileUploadInfo[]) => {
            return [
                ...prev,
                ...acceptedFiles.map((file) => {
                    return {
                        id: cuid(),
                        file,
                        isLoaded: false,
                    };
                }),
            ];
        });
    }, [setFiles]);

    const onRemoveItem = useCallback(
        (itemToRemove: FileUploadInfo) => {
            const index = files.indexOf(itemToRemove);

            if (index >= 0) {
                const updated = [...files];
                updated.splice(index, 1);
                setFiles(updated);
            }
        },
        [files, setFiles],
    );

    return (
        <div className={styles.root}>
            <div className={styles.dropContainer}>
                <Dropzone onDrop={onDrop} accept={acceptImages} disabled={disabled} maxFiles={ maxFiles ? maxFiles: 0}/>
            </div>

            {files.length > 0 ? (
                <div className={styles.attachmentsUploadingContainer}>
                    <Typography
                        className={styles.attachmentsUploadingText}
                    >{`${files.length} Attachments Uploading`}</Typography>
                </div>
            ) : null}

            {files.map((x, i) => (
                <FileUploadItem
                    key={i}
                    fileUploadInfo={x}
                    onUploadComplete={() => {}}
                    onDelete={onRemoveItem}
                />
            ))}
        </div>
    );
};

export default FileUploadComponent;