import { rootSaga } from './saga';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import showroomSlice from './showroomSlice';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        showroom: showroomSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([sagaMiddleware])
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
