import { useStripe } from "@stripe/react-stripe-js";
import { loggingService } from "../../../../utils/logging/logging";
import { Button, Stack, Typography } from "@mui/material";
import LoadingSpinner from "../../spinner/LoadingSpinner";
import { useCallback, useContext, useEffect, useState } from "react";
import { PaymentMethodRequest } from "@hmxlabs/dax-client/build/services/generated/dax-api/data-contracts";
import { daxApi } from "@hmxlabs/dax-client";
import { useNavigate } from "react-router-dom";
import styles from './StripeSaveForm.module.scss';
import { Stripe } from "@stripe/stripe-js";
import { AuthContext } from "../../../providers/AuthProvider";
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from "../../../../constants";

const {log} = loggingService('app:StripeSaveForm');

interface StripeSaveFormProps {
    setupIntentClientSecret: string
}

const StripeSaveForm = ({setupIntentClientSecret}: StripeSaveFormProps) => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });
    const {user, login} = useContext(AuthContext);
    const stripe = useStripe();
    const navigate = useNavigate();
    const [stripeError, setStripeError] = useState('');

    const saveToken = useCallback(async(stripeContext: Stripe, secret: string, isUpdate: boolean) => {
        try {
            const response = await stripeContext.retrieveSetupIntent(secret);
            
            if (response?.error) {
                throw new Error(`Error retrieving setup intent: ${response.error}`);
            }

            if (response?.setupIntent?.status !== 'succeeded') {
                throw new Error(`Error in setup intent: ${response?.setupIntent?.last_setup_error}`);
            }

            const request: PaymentMethodRequest = {
                token: response?.setupIntent?.payment_method?.toString()
            };

            if (isUpdate) {
                await daxApi.paymentMethodUpdatePaymentMethodForUser(request);
            } else {
                await daxApi.paymentMethodCreatePaymentMethodForUser(request);
            }

            // Everything has succeeded so redirect back to the user details page
            log('Storing Stripe setup successful');
            window.location.replace('/member');
        } catch (error) {
            const message = error instanceof Error ? error.message : 'Unknown error';
            log('Error storing setup', message);
            setStripeError(message);
        }
    }, []);

    useEffect(() => {
        if (user && stripe) {
            saveToken(stripe, setupIntentClientSecret, user.payment_method_attached);
        }
    }, [saveToken, setupIntentClientSecret, stripe, user]);

    const handleLoginClick = useCallback(() => {
        login(!isDesktop);
    }, [isDesktop, login]);

    const handleManageClick = useCallback(() => {
        navigate("/member");
    }, [navigate]);

    if (!user) {
        return (
            <Stack direction='column' className={styles.root}>
                <Typography>Your user account session has expired or is no longer valid.  Please log in and try again.</Typography>
                <Stack direction='row'>
                    <Button className={styles.manageButton} variant="contained" disableElevation onClick={handleLoginClick}>
                        <Typography className={styles.manageText}>Login</Typography>
                    </Button>
                </Stack>
            </Stack>
        );
    }

    if (stripeError) {
        return (
            <Stack direction='column' className={styles.root}>
                <Typography>{`An error occurring saving Stripe setup: ${stripeError}`}</Typography>
                <Stack direction='row'>
                    <Button className={styles.manageButton} variant="contained" disableElevation onClick={handleManageClick}>
                        <Typography className={styles.manageText}>Back to My Account</Typography>
                    </Button>
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack direction='column' className={styles.root}>
            <Stack direction='row' className={styles.loadingContainer}>
                <LoadingSpinner />
                <div>
                    <Typography>Finalizing Stripe setup. Please wait...</Typography>
                </div>
            </Stack>
        </Stack>
    );    
}

export default StripeSaveForm;